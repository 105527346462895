import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import EtContentEditor from "../../../utils/EtContentEditor"
import Modal from "../../../components/common/modal/Modal"


const EtTrailSessionDetailUpdate = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [fileName, setFileName] = useState("No file chosen")
    const [serverMsg, setServerMsg] = useState('')
    const [existingPic, setExistingPic] = useState('')
    //Delete section features
    const [isModalOpen, setIsModalOpen] = useState(false)
    
    const [formData, setFormData] = useState({
        trail: "",
        title: "",
        pic: "",
        content: "",
        category: "",
      })
    
    const [errors, setErrors] = useState({
        title: '',
        content: ''
      })

    // Fetch the data from the server on the page load

    useEffect(() => {
      const fetchSessionData = async () => {
        
        const response = await fetch(
          `http://localhost:4500/trails/getSessionDataForId/${id}`
        )
        if (response.ok) {
          const data = await response.json()
          console.log(data.message)
          // Set form fields with fetched data
          const sessionData = data.message.session
          setFormData({
            trail: data.message.trail,
            category: sessionData.category,
            title: sessionData.title,
            pic: '',
            content: sessionData.content
          })
          setExistingPic(`Current Pic: ${sessionData.pic}`)
        }
      }

      fetchSessionData()
    }, [id])

// Modal management functions
    const handleOpenModal = () => setIsModalOpen(true)
    const handleCloseModal = () => setIsModalOpen(false)
    
    const handleConfirm = async () => {
      handleCloseModal()

    const formData = new FormData();
    formData.append('id', id); // Append the id to FormData
    console.log('id',formData.get('id'))

    try {
        const response = await fetch('http://localhost:4500/trails/deleteSessionDataInFileForId', {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            // If the deletion was successful, navigate back to the view page
            navigate('/admin/sessions/view');
        } else {
            // Handle any errors
            console.error('Failed to delete the session detail.');
            // Optionally, you can set and display an error message to the user here
        }
    } catch (error) {
        console.error('Error during deletion:', error);
        // Optionally handle the error, such as displaying a message to the user
    }
    }

const handleChange = (e) => {
    e.preventDefault()
    const {name, value, files } = e.target
    // Clear the error for the current field immediately
    const updatedErrors = { ...errors, [name]: '' }

    if (name === "pic" && files.length > 0) {
      // Update formData with the file object
      setFormData(prevFormData => ({ ...prevFormData, [name]: files[0] }));
      // Update fileName state with the name of the file
      setFileName(files[0].name); // Store just the file name as a string
      
    } else {
      // Handle other input types
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    }
    setErrors(updatedErrors)
  }

const handleContentChange = (newContent) => {
    setFormData(prevFormData => ({ ...prevFormData, content: newContent }));
    const contentError = newContent.trim() === '' ? 'Content cannot be empty.' : '';
    setErrors(
      prevErrors => ({ ...prevErrors, content: contentError })
    );
}

let serverResponse



const handleUpdate = async () => {

    if(validateForm()) {
  
        const dataToSend = new FormData();
        Object.keys(formData).forEach(key => {
          if (key === 'pic' && formData[key]) {
            dataToSend.append(key, formData[key], formData[key].name)
           // Ensure file object is appended correctly
          } else {
            dataToSend.append(key, formData[key]); // Append other fields to FormData
          }
        })
    
        // Append 'id' to FormData
        dataToSend.append('id', id);

        try {
          const response = await fetch('http://localhost:4500/trails/updateSessionDataToFileForId', {
              method: 'POST',
              body: dataToSend, // Send as FormData
              // Do not set Content-Type header, FormData will set it
          });

          if(response.ok) {
            const result = await response.json();
            serverResponse = result.message;
            console.log(serverResponse);
            setServerMsg(serverResponse);

            // Wait for 2-3 seconds before navigating
            setTimeout(() => {
                navigate('/admin/sessions/view');
            }, 1000); // 2000 milliseconds = 2 seconds
            
        } else {
            // Handle response not OK
            console.error('Response not OK');
        }
           
            } catch (error) {
            console.error('Save error:', error);
            }
            console.log("Form is valid")
            
      } else {
            console.log("Form has errors")
      }
}

// const handleDelete = async () => {
//   handleOpenModal();
// }

const handleCancel = () => {
    navigate('/admin/sessions/view')
}


const validateForm = () => {
    let newErrors = {};
    // Trimmed content for validation
    const trimmedContent = formData.content.replace(/<[^>]*>/g, '').trim()

    if (!formData.title.trim()) {
      newErrors.title = 'Title is required.';
    }
    
    if (trimmedContent === '') {
      newErrors.content = 'Content cannot be empty.';
    }
    setErrors(newErrors);
  
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }


  return (
    <div className="bg-gray-200 p-1">
        <div className="flex flex-col justify-start items-center w-1/2 mx-auto my-5 pb-5 bg-white rounded-lg shadow-lg shadow-black">
          <div className="bg-gradient-to-r from-orange-800 to-orange-400 mb-5 w-full p-1 text-white text-center rounded-t-lg shadow-md shadow-black">
            <p className="text-4xl font-bold">Edit session detail</p>
          </div>

          {serverMsg && (
            <div className="flex justify-between w-full m-1 p-1 bg-black ">
              <p className="text-orange-600 text-sm p-1">{serverMsg}</p>
              {/* <p
                className="text-white text-sm p-1 cursor-pointer"
                onClick={dismissMsg}
              >
                Dismiss
              </p> */}
              
            </div>
          )}

          <div className="mx-5 px-5 space-y-3">
            
          <input
              type="text"
              name="trail"
              placeholder="Enter the name of the trail here"
              value={formData.trail}
              onChange={handleChange}
              className={`w-full border mt-2 p-1 rounded outline-none text-sm text-gray-600}`}
              readOnly
            />
          
          <input
              type="text"
              name="category"
              placeholder="Enter the name of the category here"
              value={formData.category}
              onChange={handleChange}
              className={`w-full border mt-2 p-1 rounded outline-none text-sm text-gray-600}`}
              readOnly
            />
          
          <input
              type="text"
              name="existingPic"
              placeholder="Enter the existing pic here"
              value={existingPic}
              onChange={handleChange}
              className={`w-full border mt-2 p-1 rounded outline-none text-sm text-gray-600}`}
              readOnly
            />
            

            <input
              type="text"
              name="title"
              placeholder="Enter the title of the session detail here"
              value={formData.title}
              onChange={handleChange}
              className={`w-full border mt-2 p-1 rounded outline-none text-sm italic text-gray-600 ${
                errors.title ? "border-red-500" : "border-orange-600"
              }`}
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title}</p>
            )}
            <input
              type="file"
              id="fileInput"
              name="pic"
              onChange={handleChange}
              className="hidden"
            />

            <div className="flex justify-start">
              <label
                htmlFor="fileInput"
                className="text-sm italic cursor-pointer inline-block hover:bg-gradient-to-r hover:from-orange-600 hover:to-orange-800 hover:border-orange-600 border border-gray-600 bg-transparent hover:text-white text-orange-600 py-2 px-4 rounded"
              >
                Choose an image
              </label>
              <div id="fileName" className="mt-2 text-sm text-gray-500 mx-4 ">
                {fileName}
                {errors.pic && (
                  <p className="text-red-500 text-xs">{errors.pic}</p>
                )}
              </div>
            </div>

            <div className="">
              <EtContentEditor
                value={formData.content}
                handleChange={handleContentChange}
              />
              {errors.content && (
                <p className="text-red-500 text-xs">{errors.content}</p>
              )}
            </div>

            <div className="my-2 py-1 flex justify-start">
              <button
                className="btn hover:bg-gradient-to-r hover:from-orange-600 hover:to-orange-800 hover:border-none hover:shadow-lg hover:shadow-black border border-orange-600 bg-transparent w-32 p-2 hover:text-white text-orange-600 rounded-full mx-1"
                onClick={handleUpdate}
              >
                Update
              </button>
              <button
                className="btn hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:border-none hover:shadow-lg hover:shadow-black border border-orange-600 bg-transparent w-32 p-2 hover:text-white text-orange-600 rounded-full mx-1"
                onClick={handleOpenModal}
              >
                Delete
              </button>
              <button
                className="btn hover:bg-gradient-to-r hover:from-black hover:via-slate-800 hover:to-black hover:border-none hover:shadow-lg hover:shadow-black border border-gray-600 bg-transparent w-32 p-2 hover:text-white text-gray-600 rounded-full mx-1"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
        </div>{" "}
      </div>
  )
}

export default EtTrailSessionDetailUpdate