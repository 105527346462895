import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'

import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'
import { userProfileIcon, pwdIcon } from '../../assets/img/imgAssets'


const Login = () => {
  useTitle('Et - Login')

  const userRef = useRef()
  const errRef = useRef()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [persist, setPersist] = usePersist()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login, { isLoading }] = useLoginMutation()

  useEffect(() => {
    userRef.current.focus()
    }, [])

  useEffect(() => {
    setErrMsg('');
  }, [username, password])


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
        const { accessToken } = await login({ username, password }).unwrap()
        dispatch(setCredentials({ accessToken }))
        setUsername('')
        setPassword('')
        navigate('/etapp')
    } catch (err) {
      const errorMessage = err?.data?.message || 'An unexpected error occurred';
        setErrMsg(errorMessage); // Update errMsg based on server response or a fallback error message
    }
  }

  useEffect(() => {
    if (errMsg) {
        errRef.current?.focus();
    }
}, [errMsg])

  const handleUserInput = (e) => setUsername(e.target.value)
  const handlePwdInput = (e) => setPassword(e.target.value)
  const handleToggle = (e) => setPersist(prev => !prev)

  if (isLoading) return <p className='text-orange-800 text-xl font-oswald font-bold'>Loading...</p>

  const content =  (
    <div className="flex flex-col bg-scroll bg-cover lg:flex-row items-center justify-center h-screen bg-gradient-to-r from-gray-400 to-gray-400 via-black">

        <div className='mx-auto rounded-2xl w-1/3 bg-gray-200 px-5 py-5 z-40 shadow-2xl shadow-slate-600'>
          <p ref={errRef} className='text-orange-800 text-xl font-oswald font-bold' aria-live="assertive">{errMsg}</p>

          <div className='flex flex-col items-center'>
            <h3 className="lg:my-2 py-5 text-black  text-xl lg:text-3xl font-bold">Sign In</h3>
          </div>
          
          <form className="space-y-1 font-poppins" onSubmit={handleSubmit}>

            <div className='relative mx-2 justify-evenly items-center'>
              
                <img 
                    src={userProfileIcon}
                    className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                    alt=''
                  />
                  <input
                    id="username"
                    type="text"
                    ref={userRef}
                    value={username}
                    placeholder='Enter your username here'
                    className="pl-12 pr-2 w-full rounded-md border-0 py-3 text-md text-gray-600 shadow-lg z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-xs leading-6 focus:outline-none"
                    onChange={handleUserInput}
                    autoComplete="off"
                    required
                  />
            </div>

            <div className='relative mx-2 justify-evenly items-center'>
                <img 
                    src={pwdIcon}
                    className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                    alt=''
                  />
               <input
                    id="password"
                    type="password"
                    value={password}
                    placeholder='Enter your password here'
                    className="pl-12 pr-2 w-full rounded-md border-0 py-3 text-xs text-gray-600 shadow-lg z-30 shadow-slate-600 placeholder:text-gray-400 text-md leading-6 focus:outline-none"
                    onChange={handlePwdInput}
                    required
                  />
            </div>

            <div className="py-5 flex flex-col items-center">
                <button 
                  className="block rounded-full bg-gradient-to-r from-orange-800 to-orange-400 w-20 px-3 py-2 text-center text-white text-md shadow-lg hover:bg-gradient-to-r hover:from-orange-400 hover:to-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 shadow-black"
                  >
                    Sign In
                </button>
              </div>

              <div className='pb-10'>
                  <label className="block text-sm font-oswaldtext-xs leading-6 text-gray-800" htmlFor="persist">
                            
                  <input
                      id="persist"
                      type="checkbox"
                      checked={persist}
                      onChange={handleToggle}
                      className="h-4 w-4 mr-5 rounded border-gray-300 text-orange-800 focus:ring-orange-600"
                    />   
                    Trust this device 
                  </label>
              </div>
              
          </form>
            <div className='py-30 flex flex-wrap justify-between'>
              <Link to="/forgot-password" className='text-black text-sm font-bold'>Forgot Password?</Link>
              <Link to="/signup" className='text-orange-600 text-sm font-bold'>Sign up here</Link>
              <Link to="/" className='text-black text-sm font-bold'>Back to Home</Link>

            </div>
        </div>
    </div>
  )

  return content
}

export default Login