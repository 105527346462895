import React from 'react'

const Modal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-70 flex justify-center items-center font-poppins ">
      <div className="bg-white p-10 rounded-lg shadow-lg shadow-black max-w-md">
        <h2 className="text-lg font-bold ">Are you sure you want to delete this item?</h2>
        <p className='mb-4 text-xs '>Please remember, this action cannot be undone. If you wish to proceed click <span className='font-bold text-red-600'>"Yes"</span> , else click <span className='font-bold'>"No"</span>  to go back.</p>
        <div className="flex justify-center mx-2 px-2">
          <button
            className="w-24 bg-gradient-to-r from-red-900 to-orange-600 hover:bg-gradient-to-r hover:from-red-600 hover:to-red-700 text-white font-bold mx-2 py-2 px-4 rounded-full"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className="w-24 bg-gray-500 hover:bg-gray-700 text-white font-bold mx-2 py-2 px-4 rounded-full"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  )
}

export default Modal
