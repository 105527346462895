import React from "react"
import Carousel from "../common/carousel/Carousel"
import InfoCard from "../common/carousel/InfoCard"
// import EtTrailsUbuntuLanding from "../../pages/trails/ubuntu/EtTrailsUbuntuLanding"

const EtSessionMainDisplay = ({ activeSession, LandingComponent }) => {
  return (
    <div>
      {activeSession && activeSession.details ? (
        <Carousel>
          {activeSession.details.map((item, index) => (
            <InfoCard
              key={index}
              title={item.title}
              content={item.content}
              pic={item.pic}
            />
          ))}
        </Carousel>
      ) : (
        <div className="flex justify-center mx-auto items-center mt-10">
        <LandingComponent />
        </div>
      )}
    </div>
  )
}

export default EtSessionMainDisplay
