

import { introductionData } from "./sessions/introductionData"
// import { pciAssessmentData } from "./sessions/pciAssessmentData"
import { kickOffData } from './sessions/kickOffData'
import { groupSessionsData } from './sessions/groupSessionsData'
// import { openSessionsData } from "./sessions/openSessionsData"

import { etTrailsImg7 } from "../../../assets/img/sessionImgAssets" 

export const trailHeaderSales = {
    name: 'Sales', element: 'EtTrailsSalesLanding', pic: etTrailsImg7, home: '/trails/sales'
}

export const trailMapSessions = [
    {name: 'Introduction', date: '01-Mar-2024', details: introductionData},
    {name: 'PCI Assessment', date: '03-Mar-2024'},
    {name: 'Kick off', date: '05-Mar-2024', details:kickOffData},
    {name: 'Group Sessions', date: '05-Mar-2024', details:groupSessionsData},
    {name: 'Open Sessions', date: '05-Mar-2024'},
    {name: 'Opportunity Reviews', date: '05-Mar-2024'},
    {name: 'Win Hacks', date: '05-Mar-2024'},
]