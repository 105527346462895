import React from "react"
import ReactQuill from "react-quill"
import EtEditorToolbar, { modules, formats } from "./EtEditorToolbar"
import "react-quill/dist/quill.snow.css"


export const EtContentEditor = ({value, handleChange}) => {
  
  return (
    <div className="">
      <EtEditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={"Write something awesome..."}
        modules={modules}
        formats={formats}
        className='w-full h-full'
      />
    </div>
  )
}

export default EtContentEditor
