import { useState, useEffect } from "react"
import { useAddNewUserMutation } from "./usersApiSlice"
import useTitle from "../../hooks/useTitle"
// import { Dialog, Transition } from '@headlessui/react';
import { Link, useNavigate } from "react-router-dom";
import ModalSuccessConfirm from "../../components/common/modal/ModalSuccessConfirm";
import { 
  userProfileIcon, 
  emailIcon,
  usernameIcon,
  pwdIcon,
 } from '../../assets/img/imgAssets';

let successHeader = 'Congratulations!'
let successMsg = 'You have successfully completed the sign-up. Please check your email to complete the account verification and then sign in'

const SignUpNewUserForm = () => {

  useTitle('Signup New User')
  const [addNewUser, {
    isLoading,
    isSuccess,
    isError,
    error
}] = useAddNewUserMutation()

const [isModalOpen, setIsModalOpen] = useState(false)
const navigate = useNavigate()

const [name, setName] = useState('')
const [email, setEmail] = useState('')
const [username, setUsername] = useState('')
const [password, setPassword] = useState('')

// Modal management functions
const handleOpenModal = () => setIsModalOpen(true)
const handleCloseModal = () => setIsModalOpen(false)

const handleConfirm = () => {
  handleCloseModal()
  navigate('/login')
}


useEffect(() => {
  if (isSuccess) {
      setName('')
      setEmail('')  
      setUsername('')
      setPassword('')
      handleOpenModal()
  }
}, [isSuccess])

    const onNameChanged = e => setName(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)      
    const onUsernameChanged = e => setUsername(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)

    const onSaveUserClicked = async (e) => {
      e.preventDefault()
        await addNewUser({ name, email, username, password })
    }

  if (isLoading) return <p className='text-orange-800 text-xl font-oswald font-bold'>Loading...</p>
  const content = (
    <div className="flex flex-col bg-scroll bg-cover lg:flex-row items-center justify-center h-screen bg-gray-300">
      
      <div className="mx-auto rounded-2xl w-1/3 bg-gray-200 px-5 py-5 z-40 shadow-2xl shadow-slate-600">
          {isError && <p className="text-orange-800 text-xl font-oswald font-bold">{error?.data?.message}</p>}
        
          <div className='flex flex-col items-center'>
            <h3 className="lg:my-2 py-5 text-black text-xl lg:text-3xl font-bold">Sign Up</h3>
          </div>

        <form className="space-y-1 font-poppins" onSubmit={onSaveUserClicked}>
                <div className='relative mx-2 justify-evenly items-center '>
                    <img 
                        src={userProfileIcon}
                        className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                        alt=""
                      />
                    <input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="off"
                    required
                    placeholder="Enter your name here"
                    className="pl-12 pr-2 w-full rounded-md border-0 py-3 text-sm text-gray-900 shadow-xl z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-xs leading-6 focus:outline-none"
                    value={name}
                    onChange={onNameChanged}
                    />
                </div>
                           
                <div className='relative mx-2 justify-evenly items-center'>
                    <img 
                        src={emailIcon}
                        className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                        alt=""
                      />
                    <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="off"
                    required
                    placeholder="Enter your email here"
                    className="pl-12 pr-2 w-full rounded-md border-0 py-3 text-sm text-gray-900 shadow-xl z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-xs leading-6 focus:outline-none"
                    value={email}
                    onChange={onEmailChanged}
                    />
                </div>
                
                <div className='relative mx-2 justify-evenly items-center'>
                    <img 
                        src={usernameIcon}
                        className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                        alt=""
                      />
                    <input
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="off"
                    required
                    placeholder="Enter your username here [3-20 letters]"
                    className="pl-12 pr-2 w-full rounded-md border-0 py-3 text-sm text-gray-900 shadow-xl z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-xs leading-6 focus:outline-none"
                    value={username}
                    onChange={onUsernameChanged}
                    />
                </div>
       
                <div className='relative mx-2 justify-evenly items-center'>
                    <img 
                        src={pwdIcon}
                        className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                        alt=""
                      />
                    <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    autoComplete="off"
                    placeholder="Set your password here [4-12 chars incl. !@#$%]"
                    className="pl-12 pr-2 w-full rounded-md border-0 py-3 text-sm text-gray-900 shadow-xl z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-xs leading-6 focus:outline-none"
                    value={password}
                    onChange={onPasswordChanged}
                    />
                </div>
              
              <div className="flex items-center justify-center py-5">
                <button 
                  title="save"
                  className="block rounded-full bg-gradient-to-r from-orange-800 to-orange-400 w-20 px-3 py-2 text-center text-white text-md shadow-lg hover:bg-gradient-to-r hover:from-orange-400 hover:to-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 shadow-black"
                
                  >
                    Save
                </button>
              </div>
        </form>

        <div className='py-30 flex flex-wrap justify-between'>
              <p className='font-bold text-black text-sm'>Already signed up?</p>
              <Link to="/login" className='text-orange-600 font-bold text-sm'>Sign in here</Link>
              <Link to="/" className='text-black font-bold text-sm'>Back to Home</Link>

        </div>

         <ModalSuccessConfirm isOpen={isModalOpen} onConfirm={handleConfirm} headerMsg={successHeader} titleMsg={successMsg} />
      </div>
    </div>
  )

  return content
}

export default SignUpNewUserForm