import { etOnImg51 } from '../../../../assets/img/sessionImgAssets'
import { etOnImg4 } from '../../../../assets/img/sessionImgAssets'
import { etOnImg3 } from '../../../../assets/img/sessionImgAssets'
import { etOnImg2 } from '../../../../assets/img/sessionImgAssets'
import { etONImg } from '../../../../assets/img/sessionImgAssets'
export const groupSessionsData = [
  {
    "title": "EtOn - Selfiniton",
    "content": "<p>SelfinitON</p>",
    "category": "Group Sessions",
    "pic": etONImg,
    "id": 1709856937772,
    "date": "2024-03-08T00:15:37.772Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "EtOn - SelfinitON - 2",
    "content": "<p>EtOn - SelfinitON -</p>",
    "category": "Group Sessions",
    "pic": etOnImg2,
    "id": 1709857123488,
    "date": "2024-03-08T00:18:43.488Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "EtOn - SelfinitON - 3",
    "content": "<p>EtOn - SelfinitON - 3</p>",
    "category": "Group Sessions",
    "pic": etOnImg3,
    "id": 1709857150205,
    "date": "2024-03-08T00:19:10.205Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "EtOn - SelfinitON -4",
    "content": "<p>EtOn - SelfinitON -4</p>",
    "category": "Group Sessions",
    "pic": etOnImg4,
    "id": 1709857174023,
    "date": "2024-03-08T00:19:34.023Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "EtOn - SelfinitON -51",
    "content": "<p>EtOn - SelfinitON -51</p>",
    "category": "Group Sessions",
    "pic": etOnImg51,
    "id": 1709857252743,
    "date": "2024-03-08T00:20:52.743Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  }
];
