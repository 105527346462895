// components/sessionboard/EtSessionMyTrail.js

import React from 'react'

const EtSessionMyTrail = () => {
  return (
    <div className=''>
      <p className='p-2 m-2 text-xs font-poppins'>Your awesome journey is waiting to get started! Are you ready?</p> 
    </div>
    )
}

export default EtSessionMyTrail
