import { 
    admin2, 
    founderPic,
    footprintIcon,
    learnIcon, 
    etLogo, 
    logOutIcon,
    ubuntu1, 
    salesIcon1, 
    addIcon2, 
    // consult2,
    // deliver3,
    health1,
    starter1,
    super1,
    power1,
    viewIcon1,
    questionIcon,
    qsetIcon4,
    sessionsIcon,
 } from "../../assets/img/sessionImgAssets" 


const level2Data = [
            {item: 'Starter', image: starter1},
            {item: 'Super', image: super1},
            {item: 'Power', image: power1},
 ]

 const trailsLevel1Data = [
    { item: 'Health', navUrl: '/trails/health',image: health1, level2: level2Data},
    // { item: 'Ubuntu', navUrl: '/trails/ubuntu',image: ubuntu1, level2: level2Data},
    // { item: 'Sales', navUrl: '/trails/sales', image: salesIcon1, level2: level2Data },
    { item: 'Ubuntu', navUrl: '/trails/ubuntu',image: ubuntu1},
    { item: '8848', navUrl: '/trails/sales', image: salesIcon1},
    // { item: 'Delivery', navUrl: '/trails/delivery',image: deliver3, level2: level2Data},
    // { item: 'Consulting', navUrl: '/trails/consulting', image: consult2, level2: level2Data},
  ]

  const adminLevel2SessionsData = [
    { item: 'View all sessions', navUrl: '/admin/sessions/view',image: viewIcon1 },
    { item: 'Create a session detail', navUrl: '/admin/sessions/create',image: addIcon2 },
    // { item: 'Edit a session detail', navUrl: '/admin/sessions/edit',image: editIcon }
]

// const adminLevel2QsetData = [
//     { item: 'View Question Sets', navUrl: '/admin/qsets',image: viewIcon1 },
//     // { item: 'Create A Question Set', navUrl: '/admin/sessions/create',image: addIcon2 },
// ]

  const adminLevel1Data = [
    { item: 'Manage Sessions', image: sessionsIcon, level2:adminLevel2SessionsData },
    { item: 'Manage Question Sets', image: qsetIcon4, navUrl: '/admin/qsets'  },
    { item: 'Manage Questions', image: questionIcon, navUrl: '/admin/questions'  }
]



// const magnetsLevel1Data = [
//     { item: 'Magnets\' Journeys', navUrl: '/magnets/journeys',image: addIcon2 },
//     { item: 'Magnets\' Perspectives', navUrl: '/magnets/perspectives',image: editIcon }
// ]

const sessionNavbarLeftItems = [
    {item: 'Home', navUrl: '/etapp', pic: etLogo},
    {item: 'Learn', navUrl: '/learn', pic:learnIcon},
    {item: 'Trails', navUrl: '/trails', pic: footprintIcon, level1: trailsLevel1Data },
    // {item: 'Camps', navUrl: '/camps', pic: etLogo},
    {item: 'Admin', navUrl: '/admin', pic:admin2, level1: adminLevel1Data },
    // {item: 'Magnet\'s Corner', navUrl: '/admin', pic:admin2, level1:magnetsLevel1Data },

]

const sessionNavbarRightItems = [
    {firstName:'Atul', lastName: 'Pandey',pic:founderPic},
    {item: 'Logout', pic:logOutIcon},

    
]

export { 
    sessionNavbarLeftItems,
    sessionNavbarRightItems
}