import React from 'react'
import EtOutlet from '../EtOutlet'

const EtLearnMain = () => {
    return (
        <div>
          <EtOutlet>
            <div className=" flex flex-col min-h-screen">
              <div className="flex flex-grow mx-1 ">
                <div className="flex w-full">
                  {/* <div className="w-64 mr-1 bg-gray-200 rounded"></div> */}
                  <div className="grow bg-gray-200 rounded">
                    Learning starts here!
                  </div>
                </div>
              </div>
            </div>
          </EtOutlet>
        </div>
      )
}

export default EtLearnMain