import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import useTitle from "../../hooks/useTitle"
import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic"
import { useAddNewQuestionSetMutation } from "./questionSetsApiSlice"
import PulseLoader from "react-spinners/PulseLoader"

const EtCreateQuestionSet = () => {
  useTitle("Question Set - Add")

  const [addNewQuestionSet, { isLoading, isSuccess, isError, error }] =
    useAddNewQuestionSetMutation()

  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    setname: "",
    setdesc: "",
    pic: null,
  })

  useEffect(() => {
    if (isSuccess) {
      setFormData({
        setname: "",
        setdesc: "",
        pic: null,
      })

      navigate("/admin/qsets")
    }
  }, [isSuccess, navigate])

  // Form field updates / change handling

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (name === "pic") {
      setFormData((prevState) => ({ ...prevState, pic: files[0] }))
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  // Form Submit logic:
  const handleSubmit = async (e) => {
    e.preventDefault()

    // Initialize an object to collect the form data
    const questionSetData = {
      setname: formData.setname,
      setdesc: formData.setdesc,
    }

    if (formData.pic) {
      // Convert the image to a base64 string
      const reader = new FileReader()
      reader.onloadend = async () => {
        // Once conversion is done, add the base64 string to your data object
        questionSetData.pic = reader.result

        // Now, send the data object to the server
        try {
          console.log("Form submitted.")
          await addNewQuestionSet(questionSetData).unwrap() // Dispatch the RTK Query mutation
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(formData.pic)
    } else {
      // No image, send other data as is
      try {
        console.log("Form submitted.")
        await addNewQuestionSet(questionSetData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleCancel = () => {
    navigate("/admin/qsets")
  }

const handleClear = () => {
    setFormData({
        setname: "",
        setdesc: "",
        pic: null,
      })
}

  return (
    <div className="bg-gray-200 p-1 w-3/4 mx-auto mt-5 rounded-lg shadow-md shadow-black">
      {isLoading && <PulseLoader color={"#ea580c"} />}
      {isError && (
        <p className="text-orange-700 font-semi-bold py-2">
          {error?.data?.message}
        </p>
      )}
      <div className="bg-white p-1 m-1 rounded">
        <p className="p-1 m-1 font-bold font-poppins text-xl">
          Add a Question Set
        </p>

        <div className="bg-gray-200 rounded m-1 p-1">
          <form
            onSubmit={handleSubmit}
            className="m-1 p-1 text-sm font-poppins"
          >
            <div id="setname">
              <input
                type="text"
                name="setname"
                placeholder="Set Name"
                value={formData.setname}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="setdesc">
              <textarea
                name="setdesc"
                placeholder="Set Description"
                value={formData.setdesc}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              ></textarea>
            </div>

            <div id="pic">
              <input
                type="file"
                name="pic"
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div>

            <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
              <EtBtnFormBasic
                name={"Create"}
                type={"create"}
                handleClick={handleSubmit}
              />
              <EtBtnFormBasic
                name={"Clear"}
                type={"clear"}
                handleClick={handleClear}
              />
              <EtBtnFormBasic
                name={"Cancel"}
                type={"cancel"}
                handleClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EtCreateQuestionSet
