import React from 'react'
import { CheckIcon } from '@heroicons/react/24/outline';

const ModalSuccessConfirm = ({ isOpen, onConfirm, headerMsg, titleMsg }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-70 flex justify-center items-center font-poppins ">
      <div className="bg-white p-10 rounded-lg shadow-lg shadow-black max-w-md">
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-r from-orange-800 to-orange-400">
                        <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
        <h2 className="text-lg font-bold ">{headerMsg}</h2>
        <p className='mb-4 text-xs '>{titleMsg}</p>
        <div className="flex justify-center mx-2 px-2">
          <button
            className="w-24 bg-gradient-to-r from-red-900 to-orange-600 hover:bg-gradient-to-r hover:from-red-600 hover:to-red-700 text-white font-bold mx-2 py-2 px-4 rounded-full"
            onClick={onConfirm}
          >
            Proceed
          </button>
          
        </div>
      </div>
    </div>
  )
}

export default ModalSuccessConfirm
