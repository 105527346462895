import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate} from 'react-router-dom'
import useTitle from '../../hooks/useTitle'
import { pwdIcon } from '../../assets/img/imgAssets'
import ModalSuccessConfirm from '../../components/common/modal/ModalSuccessConfirm'

// const serverUrl = process.env.SERVER_URL || 'http://localhost:4500' 
//const serverUrl = 'https://etwebapp1-be.onrender.com'
const serverUrl = process.env.REACT_APP_SERVER_URL

let successHeader = 'Congratulations!'
let successMsg = 'You have successfully reset the password and are ready to sign in.'

const ResetPassword = () => {
  useTitle('Reset Password')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  
  const [resetToken, setResetToken] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false)
  const [resetPasswordMessage, setResetPasswordMessage] = useState('')

  // Modal management functions
const handleOpenModal = () => setIsModalOpen(true)
const handleCloseModal = () => setIsModalOpen(false)

const handleConfirm = () => {
  handleCloseModal()
  navigate('/login')
}


  const location = useLocation()
  //const navigate = useNavigate()

  useEffect(() => {
    const seacrhParams = new URLSearchParams(location.search)
    const token = seacrhParams.get('resetToken')
    // const uname = searchParams.get('username')
    if(token) {
      setResetToken(token)
    }
    
  }, [location.search])

  const handleSubmit = async (e) => {
    e.preventDefault()

    //Basic data validation
    if (!newPassword) {
      setResetPasswordMessage('Please enter a new password');
      return;
    }

    if (newPassword !== confirmPassword) {
      setResetPasswordMessage('New password must match with confirm password');
      setNewPassword('')
      setConfirmPassword('')
      return;
    }

    // password specs
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#!&*])[A-Za-z\d@#!&*]+$/;
    if (!passwordRegex.test(newPassword)) {
      setResetPasswordMessage('Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character (@, #, !, &, *)');
      return;
    }

    //send the data to server to complete the reset
    try {

      const response = await fetch(`${serverUrl}/users/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({newPassword, resetToken})
      })

      const data = await response.json()
      if(data){
        setResetPasswordMessage(data.message)
        setResetPasswordSuccess(true)
        handleOpenModal()
      }
      

    } catch (error) {
      // Error handling
        console.error('Error:', error);
    }

  }

  const handlePasswordInput = e => setNewPassword(e.target.value)
  const handleConfirmPasswordInput = e => setConfirmPassword(e.target.value)

  const content = (
    <div className="flex flex-col bg-scroll bg-cover lg:flex-row items-center justify-center h-screen bg-gradient-to-r from-gray-400 to-gray-400 via-black">
      <div className="mx-auto rounded-2xl w-1/3 bg-gray-200 px-5 py-5 z-30 shadow-2xl shadow-slate-600">
        {/* Show only if there is a password message */}
        {!resetPasswordSuccess && resetPasswordMessage && (
          <p className="lg:mx-10 py-2 text-xl text-orange-800 font-oswald font-bold">
            {resetPasswordMessage}
          </p>
        )}

        {/* Show only when there is a resetToken in the url and the server has not sent back the response */}
        {!resetPasswordSuccess && (
          <div className="flex flex-col items-center">
            <h3 className="lg:my-2 py-2 text-black font-oswald text-xl lg:text-3xl font-bold">
              Reset Password
            </h3>

            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="relative mx-2 justify-evenly items-center">
                <img
                  src={pwdIcon}
                  alt=""
                  className="-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8"
                />
                <input
                  id="password"
                  type="password"
                  placeholder="Enter your new password here"
                  value={newPassword}
                  className="pl-12 pr-2 w-[300px] rounded-md border-0 py-3 font-poppins text-gray-900 shadow-xl z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-sm leading-6 focus:outline-none"
                  onChange={handlePasswordInput}
                  autoComplete="off"
                  required
                />
              </div>

              <div className="relative mx-2 justify-evenly items-center">
                <img
                  src={pwdIcon}
                  alt=""
                  className="-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8"
                />
                <input
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm your new password here"
                  value={confirmPassword}
                  className="pl-12 pr-2 w-[300px] rounded-md border-0 py-3 font-poppins text-gray-600 shadow-xl z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-sm leading-6 focus:outline-none"
                  onChange={handleConfirmPasswordInput}
                  autoComplete="off"
                  required
                />
              </div>

              <div className="py-5 flex flex-col items-center">
                {" "}
                {/* Center align the button */}
                <button className="block rounded-full bg-gradient-to-r from-orange-800 to-orange-400 w-36 px-3 py-2 text-center text-white text-md shadow-lg hover:bg-gradient-to-r hover:from-orange-400 hover:to-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 shadow-black">
                  <p className="text-sm">Set New Password</p>
                </button>
              </div>
            </form>
          </div>
        )}
        {resetPasswordSuccess && (
        <ModalSuccessConfirm
          isOpen={isModalOpen}
          onConfirm={handleConfirm}
          headerMsg={successHeader}
          titleMsg={successMsg}
        />
      )}
      </div>

      
    </div>
  )


  return content
}

export default ResetPassword