import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import useTitle from "../../hooks/useTitle"
import EtBtnFormBasic from "../../components/common/buttons/EtBtnFormBasic"
import { useGetQuestionsQuery, useDeleteQuestionMutation } from "./questionsApiSlice"
import PulseLoader from "react-spinners/PulseLoader"
import Modal from "../../components/common/modal/Modal"
import EtContentEditorLight from "../../utils/EtContentEditorLight"

const EtDeleteQuestion = () => {

    // 1 - Set page title
    useTitle('Delete A Question')

    // 2 - Extract id from url
    const { id } = useParams()
    console.log('id is: ', id)

    // 3 - Extract question to be deleted from useGetQuestionsQuery
    const { question } = useGetQuestionsQuery('questionList', {
        selectFromResult: ( { data }) => ({
            question: data?.entities[id]
        })
    })

    // 4 - Check for returned data
    if(!question) {
        return <PulseLoader color={"#ea580c"} />
    }

    // 5 - diapatch data to the delete form
  let content

  content = <DeleteQuestionForm id={id} question={question}/>
  return content
  
}

const DeleteQuestionForm = ({id, question}) => {
    // 1 - set the navigate hook
    const navigate = useNavigate()

    // 1a - Delete confirmation Modal
    const [isModalOpen, setIsModalOpen] = useState(false)

    // 2 - mutation to delete question
    const[deleteQuestion, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useDeleteQuestionMutation()

    // 3 - Set the data received in the component props to the local variables
    const [formData, setFormData] = useState({
        questionName: question.questionName,
      })
    
    // 4. Reset the input field and navigation based on the success state 
    useEffect(() => {
        if(isSuccess) {
            setFormData({
                questionName: "",
              })
            navigate('/admin/questions')
        }
    }, [isSuccess, navigate])

    // 5. Form field updates / change handling

    // Modal management functions
    const handleOpenModal = () => setIsModalOpen(true)
    const handleCloseModal = () => setIsModalOpen(false)


  // 6. Form Submit logic:
  const handleConfirm = async (e) => {
    e.preventDefault()
    handleCloseModal()

    // Initialize an object to collect the form data
    const questionData = {
      questionName: formData.questionName,
    }
      // No image, send other data as is
      try {
        questionData.id = id
        console.log("Form submitted.")
        await deleteQuestion(questionData).unwrap() // Dispatch the RTK Query mutation
      } catch (error) {
        console.error(error)
      }
    
  }

  const handleCancel = () => {
    navigate("/admin/questions")
  }

  const handleContentChange = (newContent) => {
    setFormData(prevFormData => ({ ...prevFormData, questionName: newContent }));
}

  
return (
    <div className="bg-gray-200 p-1 w-3/4 mx-auto mt-5 rounded-lg shadow-md shadow-black">
    {isLoading && <PulseLoader color={"#ea580c"} />}
    {isError && (
      <p className="text-orange-700 font-semi-bold py-2">
        {error?.data?.message}
      </p>
    )}
    <div className="bg-white p-1 m-1 rounded">
      <p className="p-1 m-1 font-bold font-poppins text-xl">
        Delete A Question
      </p>

      <div className="bg-gray-200 rounded m-1 p-1">
        <form
          onSubmit={handleOpenModal}
          className="m-1 p-1 text-xs font-poppins"
        >
{/* 
            <div id="questionName" >
              <input
                type="text"
                name="questionName"
                placeholder="Question"
                readOnly={true}
                value={formData.questionName}
                onChange={handleChange}
                className="w-full rounded p-2 m-1"
              />
            </div> */}

          <div className="bg-white w-full rounded p-2 m-1">
            
            <EtContentEditorLight 
            value={formData.questionName}
            handleChange={handleContentChange}
            />
    
          </div>

          <div id="actions" className="bg-white m-1 px-1 py-3 rounded">
            <EtBtnFormBasic
              name={"Delete"}
              type={"create"}
              handleClick={handleOpenModal}
            />
           
            <EtBtnFormBasic
              name={"Cancel"}
              type={"cancel"}
              handleClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} />
  </div>
)

}

export default EtDeleteQuestion