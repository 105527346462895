import React, { useState } from 'react'
import { learnIcon2 } from '../../assets/img/sessionImgAssets'

const data = [
    {
        "question": "<p><i>How would you</i> <strong>describe</strong> <i>yourself?</i></p>",
        "questionSubText": "Take as many words as you wish. Afterall, it is your story for you. So why shy?",
        "hintText": "How you see yourself and not how others see you…",
        "pic": learnIcon2,
        "completed":false,
    },
    {
        "question": "<p><i>What are your key</i> <strong> accomplishments</strong> <i> in life thus far which you are super proud of?</i></p>",
        "hintText": "Think about things, moments which you would always remember with joy",
        "pic": learnIcon2,
        "completed":false,
    },
    {
        "question": "<p><i>What are your key</i> <strong>strengths</strong><i> in your view (others may or may not see those)?</i></p>",
        "hintText": "Think about what makes your feel powerful about yourself",
        "pic": learnIcon2,
        "completed":false,
    },
    {
        "question": "<p><i>What would you like to </i> <strong>change for better</strong><i> in you?</i></p>",
        "hintText": "What you aspire to do which you have not done before, or not done as well as you wished",
        "pic": learnIcon2,
        "completed":false,
    },
    {
        "question": "<p><i>What </i> <strong>steps</strong><i> have you taken for that?</i></p>",
        "hintText": "Think about your efforts you have taken to bring about the changes which you aspire for",
        "pic": learnIcon2,
        "completed":false,
    },
    {
        "question": "<p><i>How </i> <strong>effective </strong><i>those steps have been for you?</i></p>",
        "hintText": "How much of change which you aspired, have you been able to make through those efforts, programs or initiatives?",
        "pic": learnIcon2,
        "completed":false,
    },
    
]

// Create an object for setting inner HTML
const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

const InfoCardQuestion = ({ item }) => (
    <div className='w-full bg-white my-5 rounded-lg'>
        <div className='flex justify-between h-[70vh]'>
            <div className='w-1/2 ml-1 my-1 border border-gray-200 rounded'>
                <div className='flex flex-col justify-center items-center h-full'>
                    <img src={item.pic} alt="" className='w-48 h-48' />
                </div>
            </div>
            <div className='bg-gray-200 w-1/2 my-1 ml-1 mr-1 rounded'>
                <div className='flex flex-col h-full'>
                    
                {/* <div className='bg-gradient-to-r from-orange-600 to-orange-300 rounded-tl rounded-tr p-[2px] w-full'></div> */}

                    <div className='my-1 mx-2 px-2'>
                        {/* <p className='mt-3 text-lg italic'>{item.question}</p> */}
                        <div className='text-lg mt-3' dangerouslySetInnerHTML={createMarkup(item.question)}></div>
                        <p className='text-[8px] italic text-gray-400'>{item.questionSubText}</p>
                    </div>
                    <div className='grow bg-white rounded my-1 mx-1'>
                        <div className='flex h-full justify-center items-center'>
                            <p className='text-xs italic text-gray-400 px-4'>{item.hintText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const MyTrailInitiationTest = () => {
    const [currentIndex, setCurrentIndex] = useState(0)

    const handleCircleClick = index => {
        setCurrentIndex(index)
    }

    return (
      <div className="bg-gray-200 p-1 m-1 rounded">
        <div className="bg-gray-200 w-2/3 flex flex-col justify-start items-center mx-auto my-5 pb-5 rounded-lg shadow-lg shadow-black">
          <div className="bg-gradient-to-r from-orange-600 to-orange-400 via-orange-200 rounded-tl rounded-tr p-[3px] w-full"></div>

          <div className="flex w-full justify-start items-center px-5 pt-5">
            <div className="mx-1">
              <p className="text-xs">
                {" "}
                <span className="font-bold text-gray-400">About</span> you
              </p>
            </div>
            {data.map((item, index) => (
              <div
                key={index}
                className={`cursor-pointer flex justify-center items-center rounded-full w-4 h-4 border ${
                  currentIndex === index
                    ? "bg-orange-600 border-orange-600 text-white"
                    : item.completed
                    ? "bg-green-400 border-green-400 text-white"
                    : "border-orange-600"
                } mx-1`}
                onClick={() => handleCircleClick(index)}
              >
                <p className="text-[8px] text-center">{index + 1}</p>
              </div>
            ))}
          </div>

          <div className="w-full px-5">
            <InfoCardQuestion item={data[currentIndex]} />

            <div className="flex justify-between w-1/2 mx-auto my-5">
              <div className="rounded-full bg-orange-600 p-2 mx-5 w-24">
                <p className="text-white text-xs text-center">Submit</p>
              </div>
              <div className="rounded-full bg-orange-600 p-2 mx-5 w-24">
                <p className="text-white text-xs text-center">Clear</p>
              </div>
              <div className="rounded-full bg-orange-600 p-2 mx-5 w-24">
                <p className="text-white text-xs text-center">Skip</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default MyTrailInitiationTest
