import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import useAuth from '../../hooks/useAuth'
import { useUpdateUserPicMutation } from './usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import ModalSuccessConfirm from '../../components/common/modal/ModalSuccessConfirm'

let successHeader = 'Congratulations!'
let successMsg = 'Pic uploaded successfully. Click below to go back to homepage'

const EtUserPicUpload = () => {

    const { username } = useAuth()
    //console.log('username:', username)
    // let username = ''
    const [isModalOpen, setIsModalOpen] = useState(false)
    const navigate = useNavigate()
    const [userpic, setUserpic] = useState(null)
    const [updateUserPic, {
        isSuccess,
        isLoading,
        isError,
        error,
    }] = useUpdateUserPicMutation()    

// Modal management functions
const handleOpenModal = () => setIsModalOpen(true)
const handleCloseModal = () => setIsModalOpen(false)

const handleConfirm = () => {
  handleCloseModal()
  setUserpic(null)
  navigate('/etapp')
}

    useEffect(() => {
        if(isSuccess) {
            
            handleOpenModal()
        }

    },[isSuccess, navigate])
   
    // capture and set the user pic file
    const handlePicChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
       // console.log('filename', file)
        if(file) {
            setUserpic(file)
        }
    }

    // the upload pic function
    const handleSubmit = async (event) => {
        event.preventDefault()

        if(!userpic) {
            alert("Please select a picture to upload.");
            return;
        }

        const formData = new FormData()
        formData.append('username', username)
        //console.log('username in formData', username)
        formData.append('userpic', userpic)
        //console.log('userpic in formData', userpic)
        
    try {
        await updateUserPic(formData).unwrap(); // Dispatch the RTK Query mutation
    } catch (error) {
        console.log(error)
    }
        
    }

  return (
    <div className="">
        {isLoading && <PulseLoader color={'#ea580c'} />}
        {isError && <p className="text-orange-700 font-semi-bold py-2">{error?.data?.message}</p>}
      <div className='w-full bg-white m-1 p-1 rounded mx-auto'>
        <div className='bg-gray-200 m-2 p-2 rounded'>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 m-2 p-2">
          <div>
            <label className="block text-xs font-popins text-gray-700">
              Logged in as: {username}
            </label>
          </div>
          <div>
            <label
              htmlFor="userpic"
              className="block text-sm font-bold font-popins text-gray-700"
            >
              User Picture
            </label>
            <input
              id="userPic"
              name="userpic"
              type="file"
              onChange={handlePicChange}
              className="mt-1 block w-full border border-gray-300 rounded shadow-sm p-2 text-xs italic leading-4 font-popins text-gray-700"
            />
          </div>
          <button
            type="submit"
            className="block rounded-full bg-gradient-to-r from-orange-800 to-orange-400 w-36 px-3 py-2 text-center text-white text-sm shadow-lg hover:bg-gradient-to-r hover:from-orange-400 hover:to-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 shadow-black"
          >
            Upload Picture
          </button>
        </form>
        </div>
        <ModalSuccessConfirm isOpen={isModalOpen} onConfirm={handleConfirm} headerMsg={successHeader} titleMsg={successMsg} />
      </div>
    </div>
  )
}

export default EtUserPicUpload