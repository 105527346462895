// components/sessionboard/EtSessionSidebar.js

import React, { useState } from 'react'
// import { useNavigate } from 'react-router'
import EtSessionTrailMap from './EtSessionTrailMap'
import EtSessionMyTrail from './EtSessionMyTrail'
import EtSessionMyRecord from './EtSessionMyRecord'
import { startHereIcon, mapIcon2, notesIcon1 } from '../../assets/img/imgAssets'

const EtSessionSidebar = ({ header, sessions, onSessionSelect, activeIndex, onHeaderClick }) => {

  const [activeTab, setActiveTab] = useState('trailMap')
  // const navigate = useNavigate()

  const renderContent = () => {
    switch (activeTab) {
      case 'trailMap':
        return <EtSessionTrailMap 
        sessions={sessions} 
        onSessionClick={onSessionSelect} 
        activeIndex={activeIndex}
        />
      case 'myTrail':
        return <EtSessionMyTrail />
      case 'myRecord':
        return <EtSessionMyRecord />
      default:
        return <EtSessionTrailMap 
        sessions={sessions} 
        onSessionClick={onSessionSelect} 
        activeIndex={activeIndex}  
        />
    }
  }

  return (
    <div className={`font-poppins`}>
      <div className='flex flex-col justify-center items-center'>
        {/* Div 1 - Trail Bar */}
        <div className='cursor-pointer w-full rounded-t p-2 border-b border-white bg-gradient-to-r from-orange-700 to-orange-500'
         onClick={onHeaderClick}
        >
         <p className='text-center p-2'> <span className='text-black font-bold'>Trail:</span>  <span className='font-light text-gray-100'>{header.name}</span></p>
        </div>

        {/* Div 2 - Tabs */}

        { (
          <div className='flex my-2  border-b border-gray-300'>
           
          <div className={`flex flex-col justify-start items-center mx-1 px-2 cursor-pointer ${activeTab === 'trailMap' ? 'border-b-2 border-orange-500' : ''}`} onClick={() => setActiveTab('trailMap')}> 
          
            <div className={`rounded-lg w-8 h-8 ${activeTab === 'trailMap' ? 'bg-gradient-to-r  from-orange-600 to-orange-400 shadow-lg shadow-black' : 'bg-gradient-to-r from-black via-gray-800 to-black'} z-20`}>
              <img src={mapIcon2} alt="" className='w-6 h-6 m-1 p-1' />
            </div>
            
            <p className='text-xs font-poppins py-2'>Trail Map</p> 
          </div>
          
          <div className={`flex flex-col justify-start items-center mx-1 px-2 cursor-pointer ${activeTab === 'myTrail' ? 'border-b-2 border-orange-500' : ''}`} onClick={() => setActiveTab('myTrail')}> 
          <div className={`rounded-lg w-8 h-8 ${activeTab === 'myTrail' ? 'bg-gradient-to-r  from-orange-600 to-orange-400 shadow-lg shadow-black' : 'bg-gradient-to-r from-black via-gray-800 to-black'} z-20`}><img src={startHereIcon} alt="" className='w-6 h-6 m-1 p-1' /></div>
          <p className='text-xs font-poppins py-2'>My Trail</p> 
          
          </div>
          
          <div className={`flex flex-col justify-start items-center mx-1 px-2 cursor-pointer ${activeTab === 'myRecord' ? 'border-b-2 border-orange-500' : ''}`} onClick={() => setActiveTab('myRecord')}> 
          <div className={`rounded-lg w-8 h-8 ${activeTab === 'myRecord' ? 'bg-gradient-to-r  from-orange-600 to-orange-400 shadow-lg shadow-black' : 'bg-gradient-to-r from-black via-gray-800 to-black'} z-20`}><img src={notesIcon1} alt="" className='w-6 h-6 m-1 p-1' /></div>
          <p className='text-xs font-poppins py-2'>My Record</p> 
          </div>
        </div>
        )}        

        {/* Div 3 - Content */}
        <div className='flex flex-col grow overflow-auto'>
          {renderContent()}
        </div>
      </div>
    </div>
  )
}

export default EtSessionSidebar
