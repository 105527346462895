
const ubuntuTrailMap = [
    {section: 'Header'},
    {section: 'Overview'},
    {section: 'PCI Assessment'},
    {section: 'Kick off'},
    {section: 'Group Sessions'},
    {section: 'Open Sessions'},
]

const salesTrailMap = [
    {section: 'Header'},
    {section: 'Introduction'},
    {section: 'Sales PCI Assessment'},
    {section: 'Kick off'},
    {section: 'Group Sessions'},
    {section: 'Open Sessions'},
]


export const trails = [
    { name: 'Ubuntu', trailmap: ubuntuTrailMap },
    { name: 'Sales', trailmap: salesTrailMap },
]