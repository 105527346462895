import React from 'react'
import EtHeader from './EtHeader'
import EtFooter from './EtFooter'


const EtOutlet = ({children}) => {
  return (
    <div>
        <EtHeader />
        {children}
        <EtFooter />
    </div>
  )
}

export default EtOutlet