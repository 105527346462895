import React, { useState } from 'react';
import { leftIcon, rightIcon } from '../../../assets/img/imgAssets';

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const goPrev = () => {
    setActiveIndex(prev => (prev === 0 ? children.length - 1 : prev - 1));
  };

  const goNext = () => {
    setActiveIndex(prev => (prev === children.length - 1 ? 0 : prev + 1));
  };

  const goToIndex = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="flex flex-col items-center w-[800px] h-[450px] mx-auto my-5 py-5">
      {/* Only render the active InfoCard */}
      <div className="w-full h-full flex justify-center items-center">
        {React.Children.map(children, (child, index) => {
          return index === activeIndex ? child : null;
        })}
      </div>

      {/* Navigation Controls */}
      <div className="flex justify-between items-center w-full px-4 py-2">
        {/* Left Arrow */}
        {activeIndex > 0 ? (
          <div className=" flex justify-center items-center p-1 rounded-full w-8 h-8 bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black">
          <button onClick={goPrev}>
           <img src={leftIcon} alt="" className='w-6 h-6 p-1' />
          </button>
          </div>
        ) : (
          <div className=" flex justify-center items-center p-1 rounded-full w-8 h-8 bg-gray-200">
            <img src={leftIcon} alt="" className='w-6 h-6 p-1' />
          </div>
        )}

        {/* Circles for navigation */}
        <div className="flex space-x-2 bg-white rounded-full p-2 m-2 shadow-lg shadow-black">
          {children.map((_, idx) => (
            <button
              key={idx}
              onClick={() => goToIndex(idx)}
              className={`w-3 h-3 rounded-full ${idx === activeIndex ? 'bg-gradient-to-r from-orange-800 to-orange-400 shadow-lg shadow-black' : 'bg-gray-400'}`}
            ></button>
          ))}
        </div>

        {/* Right Arrow */}
        {activeIndex < children.length - 1 ? (
          <div className=" flex justify-center items-center p-1 rounded-full w-8 h-8 bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black">
          <button onClick={goNext}>
           <img src={rightIcon} alt="" className='w-6 h-6 p-1' />
          </button>
          </div>
        ) : (
          <div className=" flex justify-center items-center p-1 rounded-full w-8 h-8 bg-gray-200">
            <img src={rightIcon} alt="" className='w-6 h-6 p-1' />
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
