import React from 'react'

const EtSessionMyRecord = () => {
  return (
    <div className=''>
      <p className='p-2 m-2 text-xs font-poppins'>A world of next steps are waiting for you! The first one is to get started. Are you ready?</p> 
    </div>
    )
}

export default EtSessionMyRecord