import React from "react"
import { useNavigate } from "react-router"
import useTitle from "../../hooks/useTitle"
import { useGetQuestionsQuery } from "./questionsApiSlice"
import { addIcon, editIcon, deleteIcon } from "../../assets/img/imgAssets"
import PulseLoader from "react-spinners/PulseLoader"

const EtQuestionList = () => {
  const navigate = useNavigate()
  useTitle("Question List")
  // Use the RTK Query hook to get question sets
  const { data: questions, isError, error, isLoading } = useGetQuestionsQuery()
  console.log("questions:", questions)

  // Optional: handle loading and error states
  if (isLoading) return <PulseLoader color={"#ea580c"} />
  if (isError) return <div>Error occurred: {error?.data?.message}</div>

  // Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  return (
    <div className=" bg-gray-200 p-1 w-3/4 mx-auto my-5 rounded-lg shadow-md shadow-black">
      <div className="flex justify-start items-center p-1 m-1">
        <div>
          <p className="p-1 m-2 font-bold font-poppins text-xl">Questions</p>
        </div>
        <div>
          <img
            src={addIcon}
            alt=""
            className="h-10 w-10 cursor-pointer"
            onClick={() => navigate("/admin/questions/create")}
          />
        </div>
      </div>
      {/* Header */}
      <div
        id="header"
        className="grid grid-cols-8 space-x-2 mx-3 my-1 px-1 py-3 text-black font-bold rounded bg-gradient-to-r from-orange-600 to-orange-600 via-orange-400 shadow-md shadow-black"
      >
        <div className="col-span-1 flex justify-start">
          
        </div>

        <div className="col-span-1">
          <p className="font-poppins text-xs text-left px-2">Set Name</p>
        </div>
        <div className="col-span-2">
          <p className="font-poppins text-xs text-left px-2">Question</p>
        </div>
        <div className="col-span-2">
          <p className="font-poppins text-xs text-left px-2">Sub Text</p>
        </div>
        <div className="col-span-2">
          <p className="font-poppins text-xs text-left px-2">Hint Text</p>
        </div>
      </div>

      {/* Data */}

      {questions &&
        questions.ids.map((id) => {
          const { qsetName, questionName, questionSubText, questionHintText } =
            questions.entities[id]
          return (
            <div
              id="data"
              className="grid grid-cols-8 space-x-2 mx-3 my-1 px-1 py-3 bg-white rounded items-center"
            >
              <div className="col-span-1 flex justify-start">
                <img src={editIcon} alt="" className="h-6 w-6 p-1 m-1 cursor-pointer" onClick={() => navigate(`/admin/questions/update/${id}`)}/>
                <img src={deleteIcon} alt="" className="h-6 w-6 p-1 m-1 cursor-pointer" onClick={() => navigate(`/admin/questions/delete/${id}`)} />
              </div>
              <div className="col-span-1">
                <p className="font-poppins text-xs text-left px-2">
                  {qsetName}
                </p>
              </div>
              <div
                className="col-span-2 font-poppins text-xs text-left px-2"
                dangerouslySetInnerHTML={createMarkup(questionName)}
              ></div>
              <div className="col-span-2">
                <p className="font-poppins text-xs text-left px-2">
                  {questionSubText}
                </p>{" "}
              </div>
              <div className="col-span-2">
                <p className="font-poppins text-xs text-left px-2">
                  {questionHintText}
                </p>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default EtQuestionList
