import React, { useState,Suspense, lazy } from 'react'
import EtOutlet from '../../EtOutlet'
import EtSessionSidebar from '../../../components/sidebars/EtSessionSidebar'
import EtSessionMainDisplay from '../../../components/sidebars/EtSessionMainDisplay'
import { trailMapSessions, trailHeaderUbuntu } from '../../../data/trails/ubuntu/trailUbuntuData'


// Dynamically import the landing component based on the element key
const LandingComponent = lazy(() => import(`./${trailHeaderUbuntu.element}`));

const EtTrailsUbuntuMain = () => {

  const [activeSession, setActiveSession] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)

  const handleSessionSelect = (session, index) => {
    setActiveSession(session)
    setActiveIndex(index)
    // console.log(session)
    // console.log('Active session is: ',activeSession)
  }

  const resetActiveSession = () => {
    setActiveSession(null)
    setActiveIndex(null)
  }

  return (
    <div>
      <EtOutlet>
        <div className=" flex flex-col min-h-screen">
          <div className="flex flex-grow mx-1 ">
            <div className="flex w-full">
              <div className="w-64 mr-1 bg-gray-200 rounded">
                <EtSessionSidebar
                  header={trailHeaderUbuntu}
                  sessions={trailMapSessions}
                  onSessionSelect={handleSessionSelect}
                  activeIndex={activeIndex}
                  onHeaderClick={resetActiveSession}
                />
              </div>
              <div className="grow bg-gray-200 rounded">
                <Suspense fallback={<div>Loading...</div>}>
                  <EtSessionMainDisplay activeSession={activeSession} LandingComponent={LandingComponent} />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </EtOutlet>
    </div>
  )
}

export default EtTrailsUbuntuMain