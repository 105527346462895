//import { carouselData } from "./carouselData" 
import { overviewData } from "./sessions/overviewData"
import { pciAssessmentData } from "./sessions/pciAssessmentData"
import { kickOffData } from './sessions/kickOffData'
import { groupSessionsData } from './sessions/groupSessionsData'
import { openSessionsData } from "./sessions/openSessionsData"

import { etTrailsImg2 } from "../../../assets/img/sessionImgAssets" 

export const trailHeaderUbuntu = {
    name: 'Ubuntu', element: 'EtTrailsUbuntuLanding', pic: etTrailsImg2, home: '/trails/ubuntu'
}

export const trailMapSessions = [
    {name: 'Overview', date: '01-Mar-2024', details: overviewData},
    {name: 'PCI Assessment', date: '03-Mar-2024',details:pciAssessmentData},
    {name: 'Kick off', date: '05-Mar-2024', details:kickOffData},
    {name: 'Group Sessions', date: '05-Mar-2024', details: groupSessionsData},
    {name: 'Open Sessions', date: '05-Mar-2024', details: openSessionsData},
    // {name: 'Pre Requisites', date: '05-Mar-2024'},
    // {name: 'Group Session 1', date: '08-Mar-2024'},
    // {name: 'Group Session 2', date: '15-Mar-2024'},
    // {name: 'Group Session 3', date: '22-Mar-2024'},
    // {name: 'Group Session 4', date: '29-Mar-2024'},
    // {name: 'Group Session 5', date: '29-Apr-2024'},
    // {name: 'Group Session 6', date: '29-May-2024'},
    // {name: 'Group Session 7', date: '29-Jun-2024'},
    // {name: 'Group Session 8', date: '29-Jul-2024'},
    // {name: 'Group Session 9', date: '29-Aug-2024'},
    // {name: 'Group Session 10', date: '29-Sep-2024'},
    // {name: 'Group Session 11', date: '29-Oct-2024'},
    // {name: 'Group Session 12', date: '29-Nov-2024'},
    // {name: 'Open Session 1', date: 'To be scheduled'},
    // {name: 'Open Session 2', date: 'To be scheduled'},
    // {name: 'Open Session 3', date: 'To be scheduled'},
    // {name: 'Open Session 4', date: 'To be scheduled'},
    // {name: 'Open Session 5', date: 'To be scheduled'},
]