import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import useTitle from '../../hooks/useTitle'
import { useGetQuestionSetsQuery } from './questionSetsApiSlice'
import { addIcon, editIcon, deleteIcon, viewIcon } from '../../assets/img/imgAssets'
import EtQuestionListBySet from '../questions/EtQuestionListBySet'


const EtQuestionSetList = () => {
    const navigate = useNavigate()
    useTitle('Question Sets')
    const [showDetails, setShowDetails] = useState(false)
    const [qsetName, setQsetName ] = useState('')
    // Use the RTK Query hook to get question sets
  const { data: questionSets, error, isLoading } = useGetQuestionSetsQuery()
 
  // Optional: handle loading and error states
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error occurred: {error.error && error.error.message ? error.error.message : 'Unknown error'}</div>;

  const displaySetQuestions = (setname) => {
    setShowDetails(true)
    setQsetName(setname)
  }

  return (
    <div className="bg-gray-200 p-1 w-3/4 mx-auto my-5 rounded-lg border border-white shadow-none shadow-black">
        <div className='flex justify-start items-center p-1 m-1'>
            <div><p className="p-1 m-2 font-bold font-poppins text-xl">Question Sets</p></div>
            <div><img src={addIcon} alt="" className='h-10 w-10 cursor-pointer' onClick={() => navigate('/admin/qsets/create') } /></div>
        </div>
        
        
        <div className='flex justify-center border-t border-white'>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                {questionSets && questionSets.ids.map((id) => {
                    const {setname, setdesc, pic} = questionSets.entities[id]
                return (
                <div key={id} className="bg-white focus:bg-orange-400 rounded-lg overflow-hidden shadow-md shadow-slate-600">
                    <div className='flex justify-start items-center p-1 my-1 border-b'>
                        <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'><img src={viewIcon} alt="" className='h-6 w-6' onClick={() => displaySetQuestions(setname)}/></div>
                        <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'><img src={editIcon} alt="" className='h-6 w-6' onClick={() => navigate(`/admin/qsets/update/${id}`)}/></div>
                        <div className='flex justify-center items-center p-1 rounded-full h-6 w-6 hover:bg-gray-100 cursor-pointer'><img src={deleteIcon} alt="" className='h-6 w-6' onClick={() => navigate(`/admin/qsets/delete/${id}`)}/></div>
                        
                    </div>
                    <div className="flex flex-col items-center justify-center p-4">
                    <div className='rounded-full h-16 w-16 hover:bg-gray-50 p-2'><img src={pic} alt="Set" className="rounded mx-auto h-14 w-14"/></div>
                    <h2 className="mt-2 text-center font-bold text-xl">{setname}</h2>
                    </div>
                    <div className="border-t p-4">
                    <p className='text-xs text-gray-500 font-poppins'>{setdesc}</p>
                    </div>
                </div>
                )

                })}
            </div>
      </div>
      {showDetails && (
      <div className='m-5 border-t border-white'>
      <EtQuestionListBySet setname={qsetName}/>
      </div>
      )}
    
    </div>
  )
}

export default EtQuestionSetList