import React, { useState, useEffect } from "react"
import  { useNavigate, useLocation } from 'react-router-dom'
import { Menu } from "@headlessui/react"
import {
  sessionNavbarLeftItems,
  sessionNavbarRightItems,
} from "../../data/sessions/sessionNavbarData"


import { userProfileIcon2 } from "../../assets/img/imgAssets"

import useAuth from "../../hooks/useAuth"
import { useSendLogoutMutation } from "../../features/auth/authApiSlice"
import { useGetUserPicQuery } from "../../features/users/usersApiSlice"
import EtModalUserPicUpload from "../common/modal/EtModalUserPicUpload"


const RightArrowIcon = () => (
  <svg
    className="w-2 h-2"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="#ea580c"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5l7 7-7 7"
    />
  </svg>
)

const DownArrowIcon = () => (
  <svg
    className="w-3 h-3"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="#ea580c"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
)

const EtSessionTopNavbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [userName, setUserName] = useState('') // added to populate the username coming from DB
  const [activeTopItem, setActiveTopItem] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Modal Management
  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)
  
  // Get the username from the useAuth hook (logged in user)
  const {username, name, isAdmin} = useAuth()

  // console.log('username:', username)

  const { data: userPicUrl, isFetching: isFetchingUserPic} = useGetUserPicQuery(username)
  // console.log('Raw data:', userPicUrl)
  // console.log('Error:', queryError) // add error: queryError to the object above


  // Enhanced to differentiate items with submenus
  const handleNavigate = (url, item) => {
    // Direct navigation only for items without submenus
    if (!item.level1) {
      navigate(url);
    }
    // Always update active top-level item state
    setActiveTopItem(item.item);
  }

  //extract the firstName and save it 
  const firstName = name?.split(' ')[0] ?? ''

  // Set the username from the useAuth hook
  useEffect(() => {
    if(firstName) {
      setUserName(firstName)
    }
  }, [firstName])

  // The logout mutation - to handle the user logout

const [sendLogout, {
  isLoading,
  isError,
  error
}] = useSendLogoutMutation()

const handleLogout = async () => {
  try {
    await sendLogout().unwrap();
    navigate('/login')
  } catch (err) {
    console.error('Logout failed:', err);
  }
};

// Simplified filtering logic using isAdmin
const visibleSessionNavbarLeftItems = sessionNavbarLeftItems.filter(item => {
  // Show all items except for the "Admin" item if the user is not an admin
  return item.item !== 'Admin' || isAdmin;
});

// Detect active item based on URL path
useEffect(() => {
  const matchingItem = visibleSessionNavbarLeftItems.find(item =>
    location.pathname === item.navUrl || item.level1?.some(subItem => location.pathname.includes(subItem.navUrl))
  );
  if (matchingItem) {
    setActiveTopItem(matchingItem.item);
  }
}, [location, visibleSessionNavbarLeftItems]);

    if (isLoading) return <p className='text-orange-800'>Logging out....</p>
    if (isError) return <p className='text-orange-800'>Error: {error?.data?.message}</p>

  return (
    <div className="flex justify-between items-center z-60">
      {/* Left side top bar */}
      <div className="flex items-center justify-start m-2 p-2 ">
        {visibleSessionNavbarLeftItems.map((item, index) => (
          <Menu as="div" key={index} className="relative m-2">
            <Menu.Button 
            onClick={() => handleNavigate(item.navUrl, item)}
            className={`flex justify-between items-center mx-1 p-1 font-oswald ${activeTopItem === item.item ? 'border-b-2 border-orange-600' : ''}`}
            >
              
              <p className="px-2 text-xs text-gray-600 font-poppins">
                {item.item}
              </p>
              
              {item.level1 && <DownArrowIcon />}
            </Menu.Button>
            {item.level1 && (
              <Menu.Items className="absolute z-50 mt-2 w-48 bg-white shadow-lg shadow-black rounded divide-y divide-gray-100 focus:outline-none">
                {item.level1.map((subItem, subIndex) => (
                  <Menu.Item key={subIndex}>
                    {({ active }) =>
                      subItem.level2 ? (
                        <Menu as="div" className="relative">
                          <Menu.Button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } group flex justify-between rounded-md items-center w-full px-2 py-2 text-xs`}
                          >
                            <div className="flex items-center">
                              <div className="flex justify-center items-center rounded-full h-6 w-6 mr-2 bg-gradient-to-r from-orange-600 to-orange-400 shadow-md shadow-black">
                                <img
                                  src={subItem.image}
                                  alt=""
                                  className="h-6 w-6 p-1"
                                />
                              </div>
                              {subItem.item}
                            </div>
                            {subItem.level2 && <RightArrowIcon />}
                          </Menu.Button>
                          <Menu.Items className="absolute z-50 left-full top-0 mt-0 ml-2 w-48 bg-white shadow-lg shadow-black rounded divide-y divide-gray-100 focus:outline-none">
                            {subItem.level2.map((level2Item, level2Index) => (
                              <Menu.Item key={level2Index}>
                                {({ active }) => (
                                  <Menu.Button
                                  onClick={() => handleNavigate(level2Item.navUrl, level2Item)}
                                    className={`${
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                    } group flex rounded-md items-center w-full px-2 py-2 text-xs`}
                                  >
                                    <div className="flex justify-center items-center rounded-full h-6 w-6 mr-2 bg-gradient-to-r from-orange-600 to-orange-400 shadow-md shadow-black">
                                <img
                                  src={level2Item.image}
                                  alt=""
                                  className="h-6 w-6 p-1"
                                />
                              </div>
                                    {level2Item.item}
                                  </Menu.Button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>
                      ) : (
                        <a
                          href={subItem.navUrl}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full px-2 py-2 text-xs`}
                        >
                          <div className="flex justify-center items-center rounded-full h-6 w-6 mr-2 bg-gradient-to-r from-orange-600 to-orange-400 shadow-md shadow-black">
                            <img
                              src={subItem.image}
                              alt=""
                              className="h-6 w-6 p-1"
                            />
                          </div>

                          {subItem.item}
                        </a>
                      )
                    }
                  </Menu.Item>
                ))}
              </Menu.Items>
            )}
          </Menu>
        ))}
      </div>

      {/* Right side top bar */}
      <div className="flex items-center justify-start m-2 p-2">
        {sessionNavbarRightItems.map((item, index) => (
          <div key={index} className="m-2">
            {index === 0 ? (
              <div className="flex justify-between items-center">
                <p className="px-5 text-xs text-gray-600">
                  Hello{" "}
                  <span className="text-black font-bold">
                    {userName}!
                  </span>
                </p>
                <div className="flex justify-center h-12 w-12 rounded-full border border-white bg-gradient-to-r from-orange-600 to-orange-400 shadow-lg shadow-black cursor-pointer" onClick={handleOpenModal}>
                  {/* Display user picture or loading indicator*/}
                  {isFetchingUserPic ? (
                    <div>Loading...</div>
                  ) : userPicUrl ? (
                    <img src={userPicUrl} alt="User" className="h-10 w-10 pt-1 px-1 rounded-full" />
                  ) : (
                    <img src={userProfileIcon2} alt="User" className="h-10 w-10 p-1" />
                  )} 
                </div>
              </div>
            ) : (
              <p className="px-5 text-xs text-gray-600 cursor-pointer" onClick={handleLogout}>{item.item}</p>
            )}
          </div>
        ))}
        <EtModalUserPicUpload isOpen={isModalOpen} onClose={handleCloseModal}/>
      </div>
    </div>
  )
}

export default EtSessionTopNavbar
