const footerData = [
    {section: "Eternity Solutions Corp", sectionData: [
        {dataItem: 'connect@eternity-solutions.com'},
        {dataItem: '+1 (650) 554-1520'},
    ]},
    {section: "Et Services", sectionData: [
        {dataItem: 'Personal Effectiveness'},
        {dataItem: 'Team Effectiveness'},
        {dataItem: 'Leadership Effectiveness'},
    ]},
    {section: "Our Story", sectionData: [
        {dataItem: 'The Genesis'},
        {dataItem: 'Starting Days'},
        {dataItem: 'Last 6 years'},
        {dataItem: 'Now and Onward'},
        {dataItem: 'Et Makers'},
        {dataItem: 'Founder\'s Corner'},

    ]},
    {section: "Et Positions", sectionData: [
        {dataItem: 'Diversity'},
        {dataItem: 'Sustainability'},
        {dataItem: 'Inclusion'},
        {dataItem: 'Governance'},
        {dataItem: 'Equity'},
    ]},
    {section: "Et Equity", sectionData: [
        {dataItem: 'Model Citizen'},
        {dataItem: 'Community Service'},
        {dataItem: 'Poverty Elimination'},
        {dataItem: 'Hunger Eradication'},
    ]},
    {section: "Et Gratitude", sectionData: [
        {dataItem: 'Mothers'},
        {dataItem: 'Family'},
        {dataItem: 'Supporters'},
        {dataItem: 'Creators'},
        {dataItem: 'Enablers'},
        {dataItem: 'Mentors'},
        {dataItem: 'Mavericks'},
    ]},
    {section: "Et for you", sectionData: [
        {dataItem: 'Clients'},
        {dataItem: 'Partners'},
        {dataItem: 'Influencers'},
        {dataItem: 'Investors'},
        {dataItem: 'Interns'},
        {dataItem: 'Warriors'},
        {dataItem: 'Explorers'},
        {dataItem: 'Mentors'},
        {dataItem: 'Game Changers'},
    ]},
    {section: "You with Et ", sectionData: [
        {dataItem: 'Write with Et'},
        {dataItem: 'Present with Et'},
        {dataItem: 'Publish with Et'},
        {dataItem: 'Conversate with Et'},
        {dataItem: 'Investigate with Et'},
        {dataItem: 'Observe with Et'},
        {dataItem: 'Teach with Et'},

    ]},
    {section: "Et Talks ", sectionData: [
        {dataItem: 'Technology & Us'},
        {dataItem: 'Let it be said'},
        {dataItem: 'Before we call it a week'},
        {dataItem: 'The Human AI'},
        {dataItem: 'The Bias Code'},
        {dataItem: 'Decoding the Obvious'},
        {dataItem: 'The Alphabets of the possible'},
    ]},
    {section: "Et Codes ", sectionData: [
        {dataItem: 'Et Values'},
        {dataItem: 'Et Client Code'},
        {dataItem: 'Et Partner Code'},
        {dataItem: 'Et Sustainability Code'},
    ]},
    {section: "Et Way ", sectionData: [
        {dataItem: 'Magnetization'},
        {dataItem: 'ESSA'},
        {dataItem: '5352'},
        {dataItem: 'Silence'},
    ]},

    {section: "Et Basics ", sectionData: [
        {dataItem: 'Begin with Basics'},
        {dataItem: 'Build with Basics'},
        {dataItem: 'Boost with Basics'},
        {dataItem: 'Back to Basics'},
        {dataItem: 'Blank the slate'},
        {dataItem: 'Build the slate'},
    ]},

    {section: "Et Organization ", sectionData: [
        {dataItem: 'Et Digital Studios'},
        {dataItem: 'Et Tech Services'},
        {dataItem: 'Et Foundation'},
    ]},
    {section: "Et Books", sectionData: [
        {dataItem: 'Realize the Possible'},
        {dataItem: 'What is your growth equation?'},
        {dataItem: 'Where is your AIM?'},
        {dataItem: 'Do you have a CEO mindset?'},
        {dataItem: 'What is your PCI Radar?'},

    ]},
    {section: "Et Locations", sectionData: [
        {dataItem: 'United States'},
        {dataItem: 'India'},
    ]},
    {section: "You for Et", sectionData: [
        {dataItem: 'Bless Et'},
        {dataItem: 'Promote Et'},
        {dataItem: 'Sponsor Et'},
        {dataItem: 'Enable Et'},
        {dataItem: 'Advise Et'},
        {dataItem: 'Challenge Et'},
        {dataItem: 'Guide Et'},
    ]},
    {section: "Et Spaces", sectionData: [
        {dataItem: 'InvestigatiON'},
        {dataItem: 'IntrospectiON'},
        {dataItem: 'ImaginatiON'},
        {dataItem: 'ImplementatiON'},
        {dataItem: 'InspectiON'},
    ]},
    {section: "Et Pathway", sectionData: [
        
        {dataItem: 'InductiON'},
        {dataItem: 'InculcatiON'},
        {dataItem: 'InvigoratiON'},
        {dataItem: 'InstitutionalizatiON'},
        {dataItem: 'ImmortalizatiON'},
    ]},
    {section: "Et Engagement", sectionData: [
        
        {dataItem: 'IntroductiON'},
        {dataItem: 'IdentificatiON'},
        {dataItem: 'InitiatiON'},
        {dataItem: 'Immersion'},
        {dataItem: 'IteratiON'},
    
    ]},


]

export { footerData }