import React from "react"
import { Navigate } from "react-router"
import useAuth from "../../../hooks/useAuth"
import EtOutlet from "../../EtOutlet"
import EtCreateQuestionSet from "../../../features/questionsets/EtCreateQuestionSet"


const EtQuestionSetCreate = () => {

  const { isAdmin } = useAuth()

  // Check if the user is not an Admin
  if (!isAdmin) {
    // Redirect them to the homepage or a "Not Authorized" page
    return <Navigate to="/" />;
    // Or return a message, e.g., return <div>You do not have access to this page.</div>;
  }


  return (
    <div>
      <EtOutlet>
        <div className=" flex flex-col min-h-screen">
          <div className="flex flex-grow mx-1 ">
            <div className="flex w-full">
              {/* <div className="w-68 mr-1 bg-gray-200 rounded"></div> */}
              <div className="grow bg-gray-200 rounded">
                <EtCreateQuestionSet />
              </div>
            </div>
          </div>
        </div>
      </EtOutlet>
    </div>
  )
}

export default EtQuestionSetCreate
