import { Routes, Route } from "react-router-dom"
import EtTrailsUbuntuMain from "./pages/trails/ubuntu/EtTrailsUbuntuMain"
import EtHomeLanding from "./pages/EtHomeLanding"
import EtLearnMain from "./pages/learn/EtLearnMain"
import EtCreateSession from "./pages/admin/sessions/EtCreateSession"
import EtEditSession from "./pages/admin/sessions/EtEditSession"
import EtViewSessions from "./pages/admin/sessions/EtViewSessions"
import EtTrailsSalesMain from "./pages/trails/sales/EtTrailsSalesMain"
import MyTrailInitiationTest from "./components/common/MyTrailInitiationTest"
import useTitle from "./hooks/useTitle"
import Login from "./features/auth/Login"
import SignUpNewUserForm from "./features/users/SignUpNewUserForm"
import PersistLogin from "./features/auth/PersistLogin"
import RequireAuth from "./features/auth/RequireAuth"
import { ROLES } from "./config/role" 
// import Prefetch from "./features/auth/Prefetch"
import ResetPassword from "./features/auth/ResetPassword"
import ForgotPassword from './features/auth/ForgotPassword'
import EtUserPicUpload from "./features/users/EtUserPicUpload"
import EtQuestionSetMain from "./pages/admin/qsets/EtQuestionSetMain"
import EtQuestionSetCreate from "./pages/admin/qsets/EtQuestionSetCreate"
import EtQuestionSetUpdate from "./pages/admin/qsets/EtQuestionSetUpdate"
import EtQuestionSetDelete from "./pages/admin/qsets/EtQuestionSetDelete"
import EtQuestionsMain from "./pages/admin/questions/EtQuestionsMain"
import EtQuestionCreate from "./pages/admin/questions/EtQuestionCreate"
import EtQuestionUpdate from "./pages/admin/questions/EtQuestionUpdate"
import EtQuestionDelete from "./pages/admin/questions/EtQuestionDelete"


function App() {
  useTitle('Et Home')
  return (
    <Routes>
      <Route path="/">
        {/* Public Routes */}
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUpNewUserForm />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="test" element={<MyTrailInitiationTest />} />
        <Route path="pic-upload" element={<EtUserPicUpload />} />
       
        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
          >
            {/* <Route element={<Prefetch />}> */}
            <Route path="etapp">
              <Route index element={<EtHomeLanding />} />
            </Route>
           
            {/* Learn Routes */}
            <Route path="learn">
              <Route index element={<EtLearnMain />} />
            </Route>{" "}
            {/* End of Learn Routes */}
            
            {/* Trails Routes */}
            <Route path="trails">
              <Route path="ubuntu" element={<EtTrailsUbuntuMain />} />
              <Route path="sales" element={<EtTrailsSalesMain />} />
            </Route>{" "}
            {/* End of Trails Routes */}
           
            {/* Admin Routes */}
            <Route path="admin">
              <Route path="sessions">
                <Route path="create" element={<EtCreateSession />} />
                <Route path="edit/:id" element={<EtEditSession />} />
                <Route path="view" element={<EtViewSessions />} />
              </Route>
              
              <Route path="qsets">
                <Route index element={<EtQuestionSetMain />} />
                <Route path="create" element={<EtQuestionSetCreate />} />
                <Route path="update/:id" element={<EtQuestionSetUpdate />}/>
                <Route path="delete/:id" element={<EtQuestionSetDelete />}/>
              </Route>

              <Route path="questions">
                <Route index element={<EtQuestionsMain />} />
                <Route path="create" element={<EtQuestionCreate />} />
                <Route path="update/:id" element={<EtQuestionUpdate />} />
                <Route path="delete/:id" element={<EtQuestionDelete />} />
              </Route>

            </Route>           
           
            {/* </Route> End of Protected routes  - prefetch */}
          </Route>{" "}
          {/* End of Protected routes  - require auth*/}
        </Route>{" "}
        {/* End of Protected routes - persist login*/}
      </Route>{" "}
      {/* End of all routes */}
    </Routes>
  )
}

export default App
