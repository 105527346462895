import { etTrailsImg5 } from '../../../../assets/img/sessionImgAssets'
import { etTrailsImg4 } from '../../../../assets/img/sessionImgAssets'
import { etTrailsImg1 } from '../../../../assets/img/sessionImgAssets'
export const pciAssessmentData = [
  {
    "title": "What is PCI?",
    "content": "<p>How do you <strong>measure</strong> the outcomes?</p>",
    "category": "PCI Assessment",
    "pic": etTrailsImg1,
    "id": 1709441750107,
    "date": "2024-03-03T04:55:50.107Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "How does it matter for me?",
    "content": "<p>It helps you build <strong style=\"color: rgb(0, 102, 204);\">awareness</strong> about your current brand impact to your customers</p>",
    "category": "PCI Assessment",
    "pic": etTrailsImg4,
    "id": 1709442004997,
    "date": "2024-03-03T05:00:04.997Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "What does it cover?",
    "content": "<p><strong>THE Client PCI Assessment - CEOs</strong></p><p><br></p><p>This assessment supplements The Client PCI assessment for people who are playing the role of a Tech Services Provider CEO or a Founder (or a CxO for the organization)</p><p><br></p><p><strong>What does this measure?</strong></p><p><br></p><ol><li>Your presence efforts and the reception level of your efforts in your client audience</li><li>Your contribution efforts and the reception of your efforts in your client audience</li><li>Your impact efforts and the reception of your efforts in your client audience</li></ol><p><br></p><p>Since this is a role based assessment, it is important to first outline the key role objectives of expectations.</p><p><br></p><p>This assessment assumes that you are a Founder or a CEO or a CxO responsible for a Tech Services provider.</p><p><br></p><p>The assessment is further tailored for the CEOs who are in the Stage 1 or 2 of their venture’s growth journey, categorized as follows:</p><p><br></p><ol><li><strong>Stage 1</strong> - sparks - just started. No revenue yet</li><li><strong>Stage 2</strong> - flames - a few years. Some revenue (1-2m), some clients.</li><li><strong>Stage 3</strong> - blaze - a few years. Good sustainable client base. 25 -100m revenue</li><li><strong>Stage 4</strong> - super blaze. Large client base. 100 - 250 m revenue</li><li><strong>Stage 5</strong> - infernos. Looking to breakaway into Tier1 providers. 250 - 500m revenue</li></ol><p><br></p><p>Let’s begin!</p>",
    "category": "PCI Assessment",
    "pic": etTrailsImg5,
    "id": 1709442117594,
    "date": "2024-03-03T05:01:57.594Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    },
    "updatedAt": "2024-03-05T05:07:45.737Z"
  }
];
