import React, { useState } from "react"
import  { useNavigate } from 'react-router-dom'
import EtContentEditor from "../../../utils/EtContentEditor"
import { trails } from "../../../data/trailsData"

const EtTrailSessionDetailCreate = () => {
    const navigate = useNavigate()
    const [fileName, setFileName] = useState("No file chosen")
    const [serverMsg, setServerMsg] = useState('')
    const [selectedTrailMap, setSelectedTrailMap] = useState([])
    const [formData, setFormData] = useState({
      trail: "",
      title: "",
      pic: "",
      content: "",
      category: "",
      
    })
  
    const [errors, setErrors] = useState({
      trail: '',
      title: '',
      category: '',
      pic: '',
      content: ''
    })
  
    const goHome = () => {
      navigate('/trails/ubuntu')
    }
    
    const dismissMsg = () => {
      setServerMsg('')
    }
  
    const validateForm = () => {
      let newErrors = {};
      // Trimmed content for validation
      const trimmedContent = formData.content.replace(/<[^>]*>/g, '').trim()
  
      if (!formData.trail) {
        newErrors.trail = 'Please select a trail.';
      }
      if (!formData.title.trim()) {
        newErrors.title = 'Title is required.';
      }
      if (!formData.category) {
        newErrors.category = 'Please select a category.';
      }
      if (!formData.pic) {
        newErrors.pic = 'Please choose a file.';
      }
      if (trimmedContent === '') {
        newErrors.content = 'Content cannot be empty.';
      }
      setErrors(newErrors);
    
      // Return true if no errors
      return Object.keys(newErrors).length === 0;
    }
  
    let serverResponse
    // handle data entry changes
  
    const handleChange = (e) => {
      e.preventDefault()
      const {name, value, files } = e.target
      // Clear the error for the current field immediately
      const updatedErrors = { ...errors, [name]: '' }
  
      if (name === "pic" && files.length > 0) {
        // Update formData with the file object
        setFormData(prevFormData => ({ ...prevFormData, [name]: files[0] }));
        // Update fileName state with the name of the file
        setFileName(files[0].name); // Store just the file name as a string
        
      } else if (name === "trail") {
        // Find the selected trail and update the form state
        const selectedTrail = trails.find(trail => trail.name === value);
        setSelectedTrailMap(selectedTrail ? selectedTrail.trailmap : []);
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      }  else {
        // Handle other input types
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      }
      setErrors(updatedErrors)
    }
  
    //handle the content created inside the rich editor
  
  const handleContentChange = (newContent) => {
      setFormData(prevFormData => ({ ...prevFormData, content: newContent }));
      const contentError = newContent.trim() === '' ? 'Content cannot be empty.' : '';
      setErrors(
        prevErrors => ({ ...prevErrors, content: contentError })
      );
  }
  
  
    const handleSave = async () => {
  
      if(validateForm()) {
  
          const dataToSend = new FormData();
          Object.keys(formData).forEach(key => {
            if (key === 'pic' && formData[key]) {
              dataToSend.append(key, formData[key], formData[key].name); // Ensure file object is appended correctly
            } else {
              dataToSend.append(key, formData[key]); // Append other fields to FormData
            }
          })
  
          try {
            const response = await fetch('http://localhost:4500/trails/createSessionDetailToFile', {
                method: 'POST',
                body: dataToSend, // Send as FormData
                // Do not set Content-Type header, FormData will set it
            });
            const result = await response.json();
            serverResponse = result.message
            console.log(serverResponse);
            setServerMsg(serverResponse)
            formClear(); // Optionally clear the form on success
              } catch (error) {
              console.error('Save error:', error);
              }
              console.log("Form is valid")
              
        } else {
              console.log("Form has errors")
        }
  
    }
  
    
    const formClear = () => {
      setFormData({ trail: '', title: '', category: '', content: '', pic: '' })
      setFileName("No file chosen")
      setErrors({
      trail: '',
      title: '',
      category: '',
      pic: '',
      content: ''
      })
    }
  
    const handleClear = () => {
      setFormData({ trail: '', title: '', category: '', content: '', pic: '' })
      setFileName("No file chosen")
      setServerMsg('')
    }
  
    return (
      <div className="bg-gray-200 p-1">
        <div className="flex flex-col justify-start items-center w-1/2 mx-auto my-5 pb-5 bg-white rounded-lg shadow-lg shadow-black">
          <div className="bg-gradient-to-r from-orange-800 to-orange-400 mb-5 w-full p-1 text-white text-center rounded-t-lg shadow-md shadow-black">
            <p className="text-4xl font-bold">Create a session detail</p>
          </div>

          {serverMsg && (
            <div className="flex justify-between w-full m-1 p-1 bg-black ">
              <p className="text-orange-600 text-sm p-1">{serverMsg}</p>
              <p
                className="text-white text-sm p-1 cursor-pointer"
                onClick={dismissMsg}
              >
                Dismiss
              </p>
              <p
                className="text-white text-sm p-1 cursor-pointer"
                onClick={goHome}
              >
                Home
              </p>
              
            </div>
          )}

          <div className="mx-5 px-5 space-y-3">
            <select
              name="trail"
              value={formData.trail}
              onChange={handleChange}
              className="select select-bordered w-full p-1 rounded border border-gray-400 outline-none text-sm text-gray-600"
            >
              <option disabled value="">
                Select a trail
              </option>
              {trails.map((trail, index) => (
                  <option key={index} value={trail.name}>{trail.name}</option>
              ))}
            </select>

            {errors.trail && (
              <p className="text-red-500 text-xs">{errors.trail}</p>
            )}

            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="select select-bordered w-full p-1 rounded border border-gray-400 outline-none text-sm text-gray-600"
            >
              <option disabled value=""> Select a category </option>
              {selectedTrailMap.map((item, index) => (
                <option key={index} value={item.section}>{item.section}</option>
              ))}
            </select>
            {errors.category && (
              <p className="text-red-500 text-xs">{errors.category}</p>
            )}

            <input
              type="text"
              name="title"
              placeholder="Enter the title of the session detail here"
              value={formData.title}
              onChange={handleChange}
              className={`w-full border mt-2 p-1 rounded outline-none text-sm italic text-gray-600 ${
                errors.title ? "border-red-500" : "border-orange-600"
              }`}
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title}</p>
            )}
            <input
              type="file"
              id="fileInput"
              name="pic"
              onChange={handleChange}
              className="hidden"
            />

            <div className="flex justify-start">
              <label
                htmlFor="fileInput"
                className="text-sm italic cursor-pointer inline-block hover:bg-gradient-to-r hover:from-orange-600 hover:to-orange-800 hover:border-orange-600 border border-gray-600 bg-transparent hover:text-white text-orange-600 py-2 px-4 rounded"
              >
                Choose an image
              </label>
              <div id="fileName" className="mt-2 text-sm text-gray-500 mx-4 ">
                {fileName}
                {errors.pic && (
                  <p className="text-red-500 text-xs">{errors.pic}</p>
                )}
              </div>
            </div>

            <div className="">
              <EtContentEditor
                value={formData.content}
                handleChange={handleContentChange}
              />
              {errors.content && (
                <p className="text-red-500 text-xs">{errors.content}</p>
              )}
            </div>

            <div className="my-2 py-1 flex justify-start">
              <button
                className="btn hover:bg-gradient-to-r hover:from-orange-600 hover:to-orange-800 hover:border-none hover:shadow-lg hover:shadow-black border border-orange-600 bg-transparent w-32 p-2 hover:text-white text-orange-600 rounded-full mx-1"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="btn hover:bg-gradient-to-r hover:from-black hover:via-slate-800 hover:to-black hover:border-none hover:shadow-lg hover:shadow-black border border-gray-600 bg-transparent w-32 p-2 hover:text-white text-gray-600 rounded-full mx-1"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
        </div>{" "}
      </div>
    )
}

export default EtTrailSessionDetailCreate