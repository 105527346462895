import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useTitle from '../../hooks/useTitle'
import { 
  emailIcon,
 } from '../../assets/img/imgAssets';

//const serverUrl = process.env.SERVER_URL || 'http://localhost:4500' 
//const serverUrl = 'https://etwebapp1-be.onrender.com' 
const serverUrl = process.env.REACT_APP_SERVER_URL
   

const PasswordReset = () => {

  useTitle('Forgot Password')
      
  const [resetPasswordEmail, setResetPasswordEmail] = useState('')
  const [resetPasswordMessage, setResetPasswordMessage] = useState('')
  
const handleEmailInput = e => setResetPasswordEmail(e.target.value)

const handleSubmit = async (e) => {
  e.preventDefault()

  //Basic validation
  if(!resetPasswordEmail) {
    setResetPasswordMessage('Please enter your email');
      return;
  }

  //Send the email to the server to check and validate

  try{
    const response = await fetch(`${serverUrl}/users/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email: resetPasswordEmail })
    })

    const data = await response.json()

    setResetPasswordMessage(data.message)

  }catch (error) {
    // Error handling
    console.error('Error:', error);
  }
}

const content =  (
  <div className="flex flex-col bg-scroll bg-cover lg:flex-row items-center justify-center h-screen bg-gradient-to-r from-gray-400 to-gray-400 via-black"> 

          
      <div className='mx-auto rounded-2xl w-1/3 bg-gray-200 px-5 py-5 z-40 shadow-2xl shadow-slate-600' style={{opacity:1}}>

      {resetPasswordMessage && (
            <p className='py-2 text-orange-800 text-xl font-oswald font-bold'>{resetPasswordMessage}</p>
          )}   

        <div className='flex flex-col items-center'>
          <h3 className="lg:my-2 py-2 text-black font-oswald text-xl lg:text-3xl font-bold">Forgot Password</h3>
        
          <form className="space-y-6" onSubmit={handleSubmit}>
            
              <div className='relative mx-2 justify-evenly items-center'>
                <img 
                    src={emailIcon}
                    alt=''
                    className='-px-1 -mx-1 absolute left-2 top-1/2 transform -translate-y-1/2 h-8 w-8'
                  />
                <input
                  id="email"
                  type="email"
                  placeholder='Enter your email here'
                  value={resetPasswordEmail}
                  className="pl-12 pr-2 w-[300px] rounded-md border-0 py-3 font-poppins text-gray-600 shadow-xl z-30 shadow-slate-600 placeholder:text-gray-400 placeholder:text-sm leading-6 focus:outline-none"
                  onChange={handleEmailInput}
                  autoComplete="off"
                  required
                />
              </div>
          

            <div className="py-5 flex flex-col items-center">
              <button 
                className="block rounded-full bg-gradient-to-r from-orange-800 to-orange-400 w-36 px-3 py-2 text-center text-white text-md shadow-lg hover:bg-gradient-to-r hover:from-orange-400 hover:to-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 shadow-black"
                >
                  <p className='text-sm'>Send Reset Link</p>
              </button>
            </div>
            
        </form>

        <div className='py-30 flex flex-wrap lg:gap-10 justify-between'>
              <p className=' text-black text-sm font-bold'>Remember password?</p>
              <Link to="/login" className='text-orange-600  text-sm font-bold'>Back to Sign In</Link>
              <Link to="/" className='text-black  text-sm font-bold'>Back to Home</Link>

            </div>
        </div>
        
      </div>
  </div>
)

  return content
}

export default PasswordReset