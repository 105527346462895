import { etTrailsImg4 } from '../../../../assets/img/sessionImgAssets'
import { etTrailsImg3 } from '../../../../assets/img/sessionImgAssets'
import { etTrailsImg2 } from '../../../../assets/img/sessionImgAssets'
export const groupSessionsData = [
  {
    "title": "What do we do here?",
    "content": "<p>We investigate in a group setting, raising key questions connected to unlocking the possible.</p>",
    "category": "Group Sessions",
    "pic": etTrailsImg3,
    "id": 1708972714611,
    "date": "2024-02-26T18:38:34.611Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    },
    "updatedAt": "2024-03-03T00:28:52.903Z"
  },
  {
    "title": "How many of these",
    "content": "<p><strong>Up to 12</strong></p>",
    "category": "Group Sessions",
    "pic": etTrailsImg2,
    "id": 1709175289004,
    "date": "2024-02-29T02:54:49.004Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "What should I prepare",
    "content": "<p>Bring an <strong>investigative and open</strong> mindset</p>",
    "category": "Group Sessions",
    "pic": etTrailsImg4,
    "id": 1709220923599,
    "date": "2024-02-29T15:35:23.599Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  }
];
