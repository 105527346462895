

const EtSessionTabs = ({ trails, selectedTrail, onTrailSelect }) => {
    
    return (
        <div className="flex justify-start">
        {trails.map((trail, index) => (
            <button
                key={index}
                onClick={() => onTrailSelect(trail.trail)}
                className={`my-2 py-1 px-4 focus:outline-none ${selectedTrail === trail.trail ? 'rounded-t text-sm text-black font-bold uppercase bg-gray-200 border-b-4 border-orange-600 shadow-lg shadow-black' : 'text-sm text-gray-400'}`}
                style={{marginRight: index < trails.length - 1 ? '10px' : '0'}}
            >
                {trail.trail}
            </button>
        ))}
    </div>
    );
};

export default EtSessionTabs