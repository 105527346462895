import { etTrailsImg1 } from '../../../../assets/img/sessionImgAssets'
import { etTrailsImg2 } from '../../../../assets/img/sessionImgAssets'
import { etTrailsImg3 } from '../../../../assets/img/sessionImgAssets'
import { etTrailsImg4 } from '../../../../assets/img/sessionImgAssets'
import { etTrailsImg6 } from '../../../../assets/img/sessionImgAssets'
export const openSessionsData = [
  {
    "title": "What is this for?",
    "content": "<p>This is to help you <strong style=\"color: rgb(0, 102, 204);\">investigate any topic</strong> of your interest.</p>",
    "category": "Open Sessions",
    "pic": etTrailsImg6,
    "id": 1709164477257,
    "date": "2024-02-28T23:54:37.257Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "How often can I do this?",
    "content": "<p>Up to <strong>10 sessions</strong> are covered in the Ubuntu trail</p>",
    "category": "Open Sessions",
    "pic": etTrailsImg4,
    "id": 1709164573099,
    "date": "2024-02-28T23:56:13.099Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    },
    "updatedAt": "2024-03-03T00:25:15.016Z"
  },
  {
    "title": "What will I do in these sessions?",
    "content": "<p><strong>Open, Raise questions, Introspect and identify next steps for immediate priorities or long term wishes </strong></p>",
    "category": "Open Sessions",
    "pic": etTrailsImg3,
    "id": 1709175840636,
    "date": "2024-02-29T03:04:00.636Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  },
  {
    "title": "Who will conduct the session?",
    "content": "<p>A <strong>designated coach</strong> from Eternity Solutions.</p>",
    "category": "Open Sessions",
    "pic": etTrailsImg2,
    "id": 1709419416580,
    "date": "2024-03-02T22:43:36.580Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    },
    "updatedAt": "2024-03-02T22:51:17.834Z"
  },
  {
    "title": "Can anyone from outside join these sessions?",
    "content": "<p>Not at this time. However, we continue to expand the ways in which people can support your initiative.</p>",
    "category": "Open Sessions",
    "pic": etTrailsImg1,
    "id": 1709420001652,
    "date": "2024-03-02T22:53:21.652Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    },
    "updatedAt": "2024-03-02T22:55:13.378Z"
  }
];
