// components/sessionboard/EtSessionTrailMap.js

import React from 'react'
const EtSessionTrailMap = ({ sessions, onSessionClick, isCollapsed, activeIndex }) => {
  
  return (
    <div>
    
      <div className={`flex flex-col my-2 mx-2 p-1 bg-white rounded scrollbar overflow-y-auto max-h-[79vh] ${isCollapsed ? '' : 'w-56'}`}>
        
        {sessions.map((session, index) => (
          <div 
          key={index} 
          onClick={() => onSessionClick(session, index)} 
          className={`flex ${isCollapsed ? 'justify-center' : 'justify-items-start items-center'} cursor-pointer my-1 rounded mx-1 p-1  ${activeIndex === index ? 'bg-gradient-to-r from-orange-600 to-orange-400 shadow-md shadow-black' : 'bg-gray-200'} `}>
            {isCollapsed && <div className='bg-gradient-to-r from-orange-600 to-orange-400 w-6 h-6 rounded-full flex justify-center items-center shadow-lg z-50 shadow-black'><p className='m-0 text-xs text-center text-white font-semibold'>{index+1}</p> </div>}
                      {!isCollapsed && (
                      <>
                      <div className={`rounded-full w-8 h-8 ${activeIndex === index ? 'bg-gradient-to-r from-orange-400 to-orange-200' : 'bg-gradient-to-r from-orange-600 to-orange-400'}  mx-2 my-1 flex justify-center items-center shadow-lg z-50 shadow-black`}>
                        <p className={`m-0 font-bold text-md text-center ${activeIndex === index ? 'text-black' : 'text-white'} `}>{index+1}</p>
                      </div>
                      <p className={`m-1 p-1 text-xs ${activeIndex === index ? 'uppercase font-bold' : ''}`}>{session.name}</p>
                      </>
                      )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default EtSessionTrailMap
