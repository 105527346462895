const InfoCard = ({ title, content, pic }) => {

  // Create an object for setting inner HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

    return (
      <div className="flex m-3 justify-between rounded-lg bg-gray-200 w-[780px] shadow-lg shadow-black z-20 h-[60vh]">
        
        <div 
        className={`flex flex-col justify-evenly items-center border rounded-l-lg w-1/2 my-1 ml-1 ${
          pic ? 'bg-cover bg-center' : 'bg-gradient-to-r from-orange-600 to-orange-400'
        }`} 
        style={pic ? { backgroundImage: `url(${pic})` } : {}}
      >
        </div>
        <div className="w-1/2 mr-1 my-1 border rounded-r-lg bg-transparent overflow-auto">
          <p className="px-5 py-1 text-4xl font-bold">
            {title}
          </p>

          <div className="leading-relaxed text-sm py-1 px-5 content-container" dangerouslySetInnerHTML={createMarkup(content)}></div>
          
        </div>
       
      </div>
    )
  }
  
  export default InfoCard  