import React from 'react'
import EtSessionTopNavbar from '../components/sessionboard/EtSessionTopNavbar'

const EtHeader = () => {
  return (
    <div className="h-24 bg-gray-200 m-1 p-1 rounded">
        <EtSessionTopNavbar />
    </div>
  )
}

export default EtHeader