import { etTrailsImg1 } from '../../../../assets/img/sessionImgAssets.js'
import { etTrailsImg4 } from '../../../../assets/img/sessionImgAssets.js'
export const kickOffData = [
  {
    "title": "What will be covered?",
    "content": "<p>A <strong>guided tour</strong> of the trail including the genesis, purpose, key details, expectations and next steps.</p>",
    "category": "Kick off",
    "pic": etTrailsImg4,
    "id": 1708972961594,
    "date": "2024-02-26T18:42:41.594Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    },
    "updatedAt": "2024-03-04T21:15:27.993Z"
  },
  {
    "title": "What created this program?",
    "content": "<p>The need to help the <strong>Tech Services CEOs</strong> become a better version of themselves everyday!</p>",
    "category": "Kick off",
    "pic": etTrailsImg1,
    "id": 1709175638113,
    "date": "2024-02-29T03:00:38.113Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    }
  }
];
