import React from 'react'
import EtUserPicUpload from '../../../features/users/EtUserPicUpload'

const EtModalUserPicUpload = ({isOpen, onClose}) => {
    if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-70 flex justify-center items-center font-poppins ">
      <div className="bg-white p-10 rounded-lg shadow-lg shadow-black max-w-md relative">
        <button
          className="absolute top-0 right-0 m-2 bg-gray-200 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-full"
          onClick={onClose}
        >
          X
        </button>
        <EtUserPicUpload />
      </div>
    </div>
  )
}

export default EtModalUserPicUpload