import { etTrailsImg2 } from '../../../../assets/img/sessionImgAssets'
export const introductionData = [
  {
    "title": "The Genesis",
    "content": "<p>To step up the selling horsepower for the Tech Services CEOs in their organization</p>",
    "category": "Introduction",
    "pic": etTrailsImg2,
    "id": 1709616794760,
    "date": "2024-03-05T05:33:14.760Z",
    "stats": {
      "views": 0,
      "likes": 0,
      "comments": 0
    },
    "updatedAt": "2024-03-06T19:45:00.832Z"
  }
];
