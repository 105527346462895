import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import EtSessionTabs from './EtSessionTabs';

const EtTrailSessionsView = () => {
    const [trailsData, setTrailsData] = useState([]);
    const [selectedTrail, setSelectedTrail] = useState('');
    const navigate = useNavigate()
    

    useEffect(() => {
        const fetchSessionsData = async () => {
            const response = await fetch('http://localhost:4500/trails/getAllSessionsData');
            if (response.ok) {
                const data = await response.json();

                setTrailsData(data.message);
                setSelectedTrail(data.message[0]?.trail); // Automatically select the first trail
            } else {
                console.error('Failed to fetch sessions data');
            }
        };

        fetchSessionsData();
    }, []);

    const selectedTrailSessions = trailsData.find(trail => trail.trail === selectedTrail)?.sessions || [];

    return (
        <div className='flex flex-col w-4/5 mx-auto bg-white my-2 py-2 rounded-lg shadow-lg shadow-black overflow overflow-y-auto'>
            
            <div className='py-2 text-center w-1/2 mx-auto'><p className='py-2 text-2xl font-bold rounded-full bg-gray-100'>Session Details for Trail: <span className='text-orange-600 uppercase'>{selectedTrail}</span></p></div>
            <div className='mx-3'><EtSessionTabs trails={trailsData} selectedTrail={selectedTrail} onTrailSelect={setSelectedTrail} /></div>
            {/* Header */}
            <div id='header' className='grid grid-cols-5 space-x-2 mx-3 my-1 px-1 py-3 text-black font-bold rounded bg-gradient-to-r from-orange-600 to-orange-600 via-orange-400 shadow-md shadow-black'>
            
            
              <div><p className='font-poppins text-xs text-center'>ID</p></div>
              <div><p className='font-poppins text-xs text-left'>Category</p></div>
              <div><p className='font-poppins text-xs text-left'>Title</p></div>
              <div><p className='font-poppins text-xs text-center'>Pic</p></div>
              <div><p className='font-poppins text-xs text-center'>Content</p></div>
            </div>
            
            {selectedTrailSessions.map(({ id, title, category, pic, content }) => (
                <div key={id} className='grid grid-cols-5 space-x-2 mx-3 my-[2px] px-1 py-3 bg-gray-200 rounded items-center'>
                    <div onClick={() => navigate(`/admin/sessions/edit/${id}`)} className="cursor-pointer"><p className='font-poppins text-xs text-center text-blue-800'>{id}</p></div>
                    <div><p className='font-poppins text-xs text-left text-black'>{category}</p></div>
                    <div><p className='font-poppins text-xs text-left text-black'>{title}</p></div>
                    <div><p className='font-poppins text-xs text-center text-black'>{pic}</p></div>
                    <div className='text-xs line-clamp-2' dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
            ))}
        </div>
    );
};

export default EtTrailSessionsView;
